import axios from 'axios'
import { appCheck, firebaseAuth, getUser } from '../Services/firebase'
import config from '../config/urls.json'
import { getToken } from '@firebase/app-check'

const instance = () => {
    const defaultOptions = {
        baseURL: config.api,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    };

    const instance = axios.create(defaultOptions);

    return instance
}

const instanceAuth = () => {
    const defaultOptions = {
        baseURL: config.auth,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    };

    const instance = axios.create(defaultOptions);

    return instance
}

const api = instance();
const auth = instanceAuth();

api.interceptors.request.use(async function (config) {
    const appCheckToken = await getToken(appCheck);
    config.headers['X-Firebase-AppCheck'] = appCheckToken.token;
    return config;
});

auth.interceptors.request.use(async function (config) {
    const appCheckToken = await getToken(appCheck);
    config.headers['X-Firebase-AppCheck'] = appCheckToken.token;
    return config;
});

const getTimeLogin = async () => {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const loginTime = parseInt(await getUser().multiFactor.user.metadata.lastLoginAt);
    const finalTime = loginTime+3600*1000; //1h
    if(timestamp < finalTime){
        // Continuar o fluxo
        return;
    }else{
        // Deslogar o usuario
        firebaseAuth.signOut()
    }
}

const getHeaders = async (offSet, orderby, pageToken) => {
    await getTimeLogin();

    const token = await getUser().getIdToken();
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    if (pageToken && pageToken !== 'undefined') {
        headers.pageToken = pageToken
    }
    headers.offSet = offSet
    headers.orderby = orderby
    return headers
};
export const getInfos = async (service, offSet, orderby) => {
    return api.get(`/${service}`, {
        headers: await getHeaders(offSet, orderby)
    })
}

export const getUrlImage = async (user1, user2, idCompany) => {
    return await api.get(`general/picture/${user1}/${user2}/${idCompany}`, {
        headers: await getHeaders()
    })
}

export const getChatByEmail = async (user1Id, user2Id, tableName) => {
    return await api.get(`general/chatbetweenusers-by-email/${user1Id}/${user2Id}/${tableName}`, {
        headers: await getHeaders()
    })
}

export const getChat = async (user1Id, user2Id, tableName) => {
    return await api.get(`general/chatbetweenusers/${user1Id}/${user2Id}/${tableName}`, {
        headers: await getHeaders()
    })
}

export const getIdsByEmail = async (user1Id, user2Id, idCompany) => {
    return await api.get(`general/iduser/${user1Id}/${user2Id}/${idCompany}`, {
        headers: await getHeaders()
    })
}

export const setAuditor = async (email, phoneNumber, roles) => {
    return auth.post(
        `/auth/signup`,
        {
            email,
            phoneNumber,
            roles,
        },
        {
            headers: await getHeaders(),
        },
    );
};

export const getAuditors = async () => {
    return auth.get('/auth/users', {
        headers: await getHeaders()
    })
}

export const getQueryResults = async (column, condition, query, role, alert,
    startTime = "", endTime = "", offSet, orderby, pageToken) => {
    return api.post('/query', {
        column, condition, query, role, alert, startTime, endTime
    }, {
        headers: await getHeaders(offSet, orderby, pageToken)
    })
}

export const getCsv = async (query, alert, role, startTime, endTime, communityId) => {
    return await api.post('/csv', {
        query, alert, role, startTime, endTime, communityId
    }, {
        headers: await getHeaders()
    })
}

export const getCsvChatBetweenUsers = async (user1Id, user2Id, tableName) => {
    return await api.post('/getCsvChatBetweenUsers', {
        user1Id, user2Id, tableName
    }, {
        headers: await getHeaders()
    })
}

export const getLogs = async () => {
    return await api.get('/logs', {
        headers: await getHeaders()
    })
}

export const deleteUser = async (uid) => {
    return await auth.delete(`auth/user/${uid}`, {
        headers: await getHeaders()
    })
}

export const editUser = async (uid, roles) => {
    return await auth.put(`auth/user/${uid}`, {
        uid, roles
    }, {
        headers: await getHeaders()
    })
}

export const getWordsAlert = async () => {
    return await api.get('/alertsConfig/words', {
        headers: await getHeaders()
    })
}

export const getAdminsAlert = async () => {
    return await api.get('/alertsConfig/admin', {
        headers: await getHeaders()
    })
}

export const setWordsAlert = async (words) => {
    return await api.post('/alertsConfig/words', {
        words
    }, {
        headers: await getHeaders()
    })
}

export const setProfile = async (userName, urlImage) => {
    return await api.post('/general/edit-profile', {
        userName,
        urlImage
    }, {
        headers: await getHeaders()
    })
}

export const setAdminAlert = async (admins) => {
    return await api.post('/alertsConfig/admin', {
        admins
    }, {
        headers: await getHeaders()
    })
}

export const deleteWordsAlert = async (word) => {
    return await api.delete(`/alertsConfig/words/${word}`, {
        headers: await getHeaders()
    })
}

export const deleteAdminAlert = async (admin) => {
    return await api.delete(`/alertsConfig/admin/${admin}`, {
        headers: await getHeaders()
    })
}

