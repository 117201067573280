import React, { useContext } from 'react'

import '../../../assets/css/components/Tables/DetailsModal/WorkchatDetail.css';
import { GeneralContext } from '../../../context/GeneralContext';

import { ReactComponent as IconFecharDark } from '../../../assets/img/icons/darkmode/IconFechar.svg';

import { ReactComponent as IconFecharLight } from '../../../assets/img/icons/lightmode/IconFechar.svg';

const CommentsDetail = (props) => {

    const { theme, toggleMenu } = useContext(GeneralContext);

    // Variáveis de condições
    const widthModal = toggleMenu ? 'workchatMenuOpen' : 'workchatMenuClose';
    const workchatBoxModalTheme = theme ? 'workchatBoxModalDark' : 'workchatBoxModalLight';
    const infoBoxTheme = theme ? 'infoBoxDark' : 'infoBoxLight';

    let comment, comment_id, community_id, created_time, deleted_time, from_id, from_name, permalink, post_id, verb;

    props.row.forEach((RowSelected) => {
        if (RowSelected.comment_id === props.id) {
            comment = RowSelected.comment;
            comment_id = RowSelected.comment_id;
            community_id = RowSelected.community_id;
            created_time = RowSelected.created_time;
            deleted_time = RowSelected.deleted_time;
            from_id = RowSelected.from_id;
            from_name = RowSelected.from_name;
            permalink = RowSelected.permalink;
            post_id = RowSelected.post_id;
            verb = RowSelected.verb;
        }
    })

    return (
        <div className={`workchatModal ${widthModal}`}>
            <div className={`workchatBoxModal ${workchatBoxModalTheme}`}>
                <div className='workchatTitleBox'>
                    <span className='titleDetails'>Detalhes</span>
                    {   theme ?
                        <IconFecharDark className='closeIcon' onClick={() => props.setViewDetail(false)} />
                        :
                        <IconFecharLight className='closeIcon' onClick={() => props.setViewDetail(false)} />
                    }
                </div>
                <div className='contentModal'>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Criação</span>
                        <span>{created_time}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Id</span>
                        <span>{comment_id}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Usuário</span>
                        <span>{from_name}</span>
                    </div>
                    <div className={`infoBox infoBoxScroll ${infoBoxTheme}`}>
                        <span>Comentário</span>
                        <span>{comment}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Ação</span>
                        <span>{verb}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Link</span>
                        <span>{permalink}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Id da comunidade</span>
                        <span>{community_id}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Id da postagem</span>
                        <span>{post_id}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Id do usuário</span>
                        <span>{from_id}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Excluído em</span>
                        <span>{deleted_time}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommentsDetail