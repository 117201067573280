import React, { useContext } from 'react';

import '../../assets/css/components/DateFilter.css';
import { GeneralContext } from '../../context/GeneralContext';

const DateFilter = () => {

  const { theme, finalDate, setFinalDate } = useContext(GeneralContext);

  //Variáveis de CSS
  const labelTheme = theme ? 'labelDark' : 'labelLight';
  const inputTheme = theme ? 'dateInputDark' : 'dateInputLight';

  // Funções
  const getFinalDate = () => {  
    if (document.getElementById("final") != null) {
      const initialValue = document.getElementById('final').value;
      setFinalDate(initialValue)
    }
  }
  
  return (
    <div className='dateFilter'>
        <span className={`${labelTheme}`}>Final</span>
        <input type="date" className={`dateInput ${inputTheme}`} id="final" onChange={getFinalDate} value={finalDate} />
    </div>
  );
};

export default DateFilter;