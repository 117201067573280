import React, { useContext } from 'react';

import '../../assets/css/components/Loading.css';
import { GeneralContext } from '../../context/GeneralContext';

export const Loading = () => {

  const { theme } = useContext(GeneralContext);

  return (
    theme ?
      <>
        <div class='loaderComponentContainer loaderThemeDark'>
          <div class='lds-dual-ring-dark'></div>
        </div>
      </>
      :
      <>
        <div class='loaderComponentContainer loaderThemeLight'>
          <div class="lds-dual-ring"></div>
        </div>
      </>
  )
}