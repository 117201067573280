import React, { useState, useContext } from 'react'

import '../../assets/css/components/SelectType.css';
import { GeneralContext } from '../../context/GeneralContext';

import { ReactComponent as IconSetaDark} from '../../assets/img/icons/darkmode/IconSeta.svg'

import { ReactComponent as IconSetaLight} from '../../assets/img/icons/lightmode/IconSeta.svg'

import { useDetectClickOutside } from 'react-detect-click-outside';

const SelectType = (props) => {

    const { theme, setType, setTypeName, typeName } = useContext(GeneralContext);

    // Variáveis de estado
    const [selectToggle, setSelectToggle] = useState(false);

    // Variáveis de tema
    const headerTheme = theme ? 'selectHeaderDark' : 'selectHeaderLight';
    const optionTheme = theme ? 'selectOptionDark' : 'selectOptionLight';

    // Funções
    const handleType = (e) => {
        setType(e.target.getAttribute('value'));
        setTypeName(e.target.getAttribute('name'));
        setSelectToggle(!selectToggle);
    }

    // Variáveis de Condições
    let selectState = selectToggle ? 'selectOpen' : 'selectClose';
    let optionToggle = selectToggle ? '' : 'selectOptionHidden';
    let selectPlaceholder = typeName === '' ? 'Selecione o filtro' : typeName;
    let selectArrowToggle = selectToggle ? 'selectArrowOpen' : '';

    const handleToggle = () => {
        setSelectToggle(false)
    }

    const ref = useDetectClickOutside({ onTriggered: handleToggle })

  return (
    <div className={`selectType`} ref={ref}>
        {/* Selecionado */}
        <div className={`selectHeader ${selectState} ${headerTheme}`} onClick={()=>setSelectToggle(!selectToggle)}>
            <span>{selectPlaceholder}</span>
            {theme ? <IconSetaDark className={`selectArrow ${selectArrowToggle}`} /> : <IconSetaLight className={`selectArrow ${selectArrowToggle}`} />}
        </div>
        {/* Opções */}
        <div className={`selectOption ${optionToggle} ${optionTheme}`}>
            <div value='equals' name='é igual a' onClick={handleType}>
                <span value='equals' name='é igual a' onClick={handleType}>é igual a</span>
            </div>
            <div value='contains' name='contém' onClick={handleType}>
                <span value='contains' name='contém' onClick={handleType}>contém</span>
            </div>
        </div>
    </div>
  )
}

export default SelectType