import React, { useContext } from 'react'

import '../../../assets/css/components/Tables/DetailsModal/WorkchatDetail.css';
import { GeneralContext } from '../../../context/GeneralContext';

import { ReactComponent as IconFecharDark } from '../../../assets/img/icons/darkmode/IconFechar.svg';

import { ReactComponent as IconFecharLight } from '../../../assets/img/icons/lightmode/IconFechar.svg';

const FeedDetail = (props) => {

    const { theme, toggleMenu } = useContext(GeneralContext);

    // Variáveis de condições
    const widthModal = toggleMenu ? 'workchatMenuOpen' : 'workchatMenuClose';
    const workchatBoxModalTheme = theme ? 'workchatBoxModalDark' : 'workchatBoxModalLight';
    const infoBoxTheme = theme ? 'infoBoxDark' : 'infoBoxLight';

    let author_id, author_name, message, status_id, timestamp, user_id, user_name, verb;

    props.row.forEach((RowSelected) => {
        if (RowSelected.author_id === props.id) {
            author_id = RowSelected.author_id;
            author_name = RowSelected.author_name;
            message = RowSelected.message;
            status_id = RowSelected.status_id;
            timestamp = RowSelected.timestamp;
            user_id = RowSelected.user_id;
            user_name = RowSelected.user_name;
            verb = RowSelected.verb;
        }
    })
    
    return (
            <div className={`workchatModal ${widthModal}`}>
                <div className={`workchatBoxModal ${workchatBoxModalTheme}`}>
                    <div className='workchatTitleBox'>
                        <span className='titleDetails'>Detalhes</span>
                        {   theme ?
                            <IconFecharDark className='closeIcon' onClick={() => props.setViewDetail(false)} />
                            :
                            <IconFecharLight className='closeIcon' onClick={() => props.setViewDetail(false)} />
                        }
                    </div>
                    <div className='contentModal'>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>Criação</span>
                            <span>{timestamp}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>Id</span>
                            <span>{user_id}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>Nome</span>
                            <span>{user_name}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>Autor</span>
                            <span>{author_name}</span>
                        </div>
                        <div className={`infoBox infoBoxScroll ${infoBoxTheme}`}>
                            <span>Mensagem</span>
                            <span>{message}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>Ação</span>
                            <span>{verb}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>Id do autor</span>
                            <span>{author_id}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>Id do usuário</span>
                            <span>{user_id}</span>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default FeedDetail