import React, { useState, useEffect, useContext } from 'react'
import { saveAs } from "file-saver";
import '../assets/css/pages/Activity.css'

import { useNavigate } from 'react-router-dom';

import {firebaseAuth} from '../Services/firebase';

import MainMenu from '../components/organisms/MainMenu';
import Title from '../components/atoms/Title';
import InitialDate from '../components/atoms/InitialDate';
import FinalDate from '../components/atoms/FinalDate';

import SelectCompany from '../components/atoms/SelectCompany';
import SelectType from '../components/atoms/SelectType';
import SelectAgentWorkchat from '../components/atoms/SelectAgentWorkchat';
import SelectAgentComments from '../components/atoms/SelectAgentComments';
import SelectAgentPosts from '../components/atoms/SelectAgentPosts';
import SelectAgentFeed from '../components/atoms/SelectAgentFeed';
import WorkchatTable from '../components/molecules/WorkchatTable';
import CommentsTable from '../components/molecules/CommentsTable';
import PostsTable from '../components/molecules/PostsTable';
import FeedTable from '../components/molecules/FeedTable';

import { getInfos, getCsv, getQueryResults } from '../api/api';
import { Loading } from '../components/atoms/Loading';
import { GeneralContext } from '../context/GeneralContext';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Activity = (props) => {

    const { theme, toggleMenu, company, initialDate, setInitialDate, finalDate, setFinalDate, type, setType, setTotRows, setTypeName, isFilter, setIsFilter, agentWorkchat, setAgentWorkchat, agentFeed, setAgentFeed, agentComments, setAgentComments, agentPosts, setAgentPosts, setAgentWorkchatName, setAgentCommentsName, setAgentPostsName, setAgentFeedName, setIsAuth, content, setContent, pageCurrent, setPageCurrent } = useContext(GeneralContext);

    const navigate = useNavigate();

    //Variáveis comuns
    let nameDashboard = 'Selecione uma empresa';

    //Variáveis de Estado
    const [agent, setAgent] = useState('');
    const [selectedDashboard, setSelectedDashboard] = useState("");
    const [dashboards, setDashboards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [rowFilter, setRowFilter] = useState();

    //Variáveis de troca de aba
    const [selectedTab, setSelectedTab] = useState('workchat');
    const workchatTab = selectedTab === 'workchat' ? 'selectedTab' : '';
    const commentsTab = selectedTab === 'comments' ? 'selectedTab' : '';
    const postsTab = selectedTab === 'posts' ? 'selectedTab' : '';
    const feedTab = selectedTab === 'feed' ? 'selectedTab' : '';

    //Variáveis de CSS
    const contentPageToggle = toggleMenu ? 'contentPageOpen' : 'contentPageClose';
    const contentPageTheme = theme ? 'contentPageDark' : 'contentPageLight';
    const tabActivityTheme = theme ? 'tabActivityDark' : 'tabActivityLight';
    const tabOptionTheme = theme ? 'tabOptionDark' : 'tabOptionLight';
    const inputTextTheme = theme ? 'inputTextDark' : 'inputTextLight';
    const backgroundTheme = theme ? 'backgroundDark' : 'backgroundLight';

    const [offSet, setOffSet] = useState(0);
    const [triggerReq, setTriggerReq] = useState(false);
    const [alertTheme, setAlertTheme] = useState(theme ? "dark":"light")

    useEffect(() => {
        firebaseAuth.onAuthStateChanged(user => {
            if (user) {
                (async () => {
                    setIsLoading(true)
                    setIsAuth(true)
                    const res = await getInfos('general/communities');
                    setDashboards(res.data);
                    setSelectedDashboard(company);
                    setIsLoading(false)
                })();
            } else {
                setIsAuth(false)
                navigate('/')
            }
        })
    }, [props.history, company])

    const setValues = () => {
        if(dashboards.length > 0){
            dashboards.forEach((dash) => {
                if (dash.community_id === selectedDashboard) {
                    nameDashboard = dash.community_name;
                }
            })
        }
    }
    setValues();

    const downloadCsv = async () => {
        setAlertTheme(theme ? "dark":"light")
        if (finalDate < initialDate) {
            toast.error('A data de início não deve ser maior que a data de fim!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: `${alertTheme}`,
            });
            return;
        }
        setIsLoading(true);
        if (initialDate !== '' && finalDate !== '' && company !== 'Selecione uma empresa') {
            const res = await getCsv("", "", selectedTab, initialDate, finalDate, company);

            if (res.status === 400) {
                setIsLoading(false);
                return;
            }

            const blob = new Blob(["", res.data], {
                type: "text/csv;charset=utf-8",
            });
            saveAs(blob, `csv_${selectedTab}_${new Date().getTime() / 1000}.csv`);
        }
        else {
            toast.info('Selecione todos os campos necessários: \nEmpresa, data de início e data de término', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: `${alertTheme}`,
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (isFilter) {
            setOffSet(pageCurrent)
            setTriggerReq(!triggerReq)
        }
    }, [pageCurrent])

    useEffect(() => {
        if(type !== null && content !== null && initialDate && finalDate && company !== "Selecione uma empresa"){
            (async () => {
                setIsLoading(true)
                let res;
                if (selectedTab === 'workchat') {
                    res = await getQueryResults(agentWorkchat, type, content, selectedTab, 'false', initialDate, finalDate, offSet, "created_time")
                } else if (selectedTab === 'comments') {
                    res = await getQueryResults(agentComments, type, content, selectedTab, 'false', initialDate, finalDate, offSet, "created_time")
                } else if (selectedTab === 'posts') {
                    res = await getQueryResults(agentPosts, type, content, selectedTab, 'false', initialDate, finalDate, offSet, "created_time")
                } else if (selectedTab === 'feed') {
                    res = await getQueryResults(agentFeed, type, content, selectedTab, 'false', initialDate, finalDate, offSet, "timestamp")
                }
                res.data.forEach((value) => {
                    if (value.community_id === company) {
                        setRowFilter(value.data.rows)
                        setTotRows(value.data.totalResults)
                    }
                })
                setIsLoading(false);
            })()
        }
    }, [triggerReq])

    //Se mudar de tabela reseta todos os valores
    useEffect(() => {
        setIsFilter(false);
        setInitialDate('');
        setFinalDate('');
        setAgent('');
        setAgentWorkchatName('');
        setAgentPostsName('');
        setAgentFeedName('');
        setAgentCommentsName('');
        setAgentWorkchat('');
        setAgentPosts('');
        setAgentFeed('');
        setAgentComments('');
        setType(null);
        setContent('');
        setTypeName('')
        setOffSet(0);
        setPageCurrent(0);
        setRowFilter(undefined)
    }, [selectedTab])

    const getFilter = async () => {
        if (type !== null && content !== null && initialDate && finalDate && company !== "Selecione uma empresa") {
            if (content === '') {
                toast.error('Preencha todos os campos!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: `${alertTheme}`,
                });
                return;
            }
            if (finalDate < initialDate) {
                toast.error('A data de início não deve ser maior que a data de fim!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: `${alertTheme}`,
                });
                return;
            }
            if (agentWorkchat !== '' || agentFeed !== '' || agentPosts !== '' || agentComments !== '') {
                setIsFilter(true);
                setTriggerReq(!triggerReq)
            }
            else {
                toast.error('Preencha todos os campos!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: `${alertTheme}`,
                });
                return;
             }
        } else { 
            toast.error('Preencha todos os campos!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: `${alertTheme}`,
            });
            return;
        }
    }

    return (
        <div className='activity'>
            <ToastContainer />
            <MainMenu />
            <div className={`contentPage ${contentPageToggle} ${contentPageTheme}`}>
                <div className={`${backgroundTheme}`} />
                <Title />
                {isLoading && <Loading />}
                <div className='companyBox'>
                    <SelectCompany
                        dashboards={dashboards}
                        nameDashboard={nameDashboard}
                    />
                    <button className='btnCsv' onClick={() => { downloadCsv() }}>Download CSV</button>
                </div>
                {/* Menu de seções */}
                <div className={`tabActivity ${tabActivityTheme}`}>
                    <div className='tabBox'>
                        <div className={`tabOption ${tabOptionTheme} ${workchatTab}`} onClick={() => { setSelectedTab('workchat') }} >Workchat</div>
                        <div className={`tabOption ${tabOptionTheme} ${commentsTab}`} onClick={() => { setSelectedTab('comments') }} >Comments</div>
                        <div className={`tabOption ${tabOptionTheme} ${postsTab}`} onClick={() => { setSelectedTab('posts') }} >Posts</div>
                        <div className={`tabOption ${tabOptionTheme} ${feedTab}`} onClick={() => { setSelectedTab('feed') }} >Feed</div>
                    </div>
                </div>
                <div className='filterField'>
                    <InitialDate />
                    <FinalDate />
                    {
                        ((selectedTab === 'workchat' && <SelectAgentWorkchat />)
                            ||
                            (selectedTab === 'comments' && <SelectAgentComments />)
                            ||
                            (selectedTab === 'posts' && <SelectAgentPosts />)
                            ||
                            (selectedTab === 'feed' && <SelectAgentFeed />))
                    }
                    <SelectType />
                    <input type='text' className={`inputText ${inputTextTheme}`} placeholder='Insira o conteúdo' onChange={e => setContent(e.target.value)} value={content} />
                    <button className='btnFilter' onClick={getFilter}>Pesquisar</button>
                </div>
                {selectedTab === 'workchat' && <WorkchatTable selectedTab={selectedTab} rowFilter={rowFilter} />}
                {selectedTab === 'comments' && <CommentsTable selectedTab={selectedTab} rowFilter={rowFilter} />}
                {selectedTab === 'posts' && <PostsTable selectedTab={selectedTab} rowFilter={rowFilter} />}
                {selectedTab === 'feed' && <FeedTable selectedTab={selectedTab} rowFilter={rowFilter} />}

            </div>
        </div>
    )
}

export default Activity