import React, { useState, useContext } from 'react'

import '../../assets/css/components/SelectAgent.css';
import { GeneralContext } from '../../context/GeneralContext';

import { ReactComponent as IconSetaDark} from '../../assets/img/icons/darkmode/IconSeta.svg'

import { ReactComponent as IconSetaLight} from '../../assets/img/icons/lightmode/IconSeta.svg'

import { useDetectClickOutside } from 'react-detect-click-outside';

const SelectAgent = (props) => {

    const { theme, setAgentPosts, agentPostsName, setAgentPostsName } = useContext(GeneralContext);

    // Variáveis de estado
    const [selectToggle, setSelectToggle] = useState(false);

    // Variáveis de tema
    const headerTheme = theme ? 'selectHeaderDark' : 'selectHeaderLight';
    const optionTheme = theme ? 'selectOptionDark' : 'selectOptionLight';

    // Funções
    const handleAgent = (e) => {
        setAgentPosts(e.target.getAttribute('value'));
        setAgentPostsName(e.target.getAttribute('name'));
        setSelectToggle(!selectToggle);
    }

    // Variáveis de Condições
    let selectState = selectToggle ? 'selectOpen' : 'selectClose';
    let optionToggle = selectToggle ? '' : 'selectOptionHidden';
    let selectPlaceholder = agentPostsName === '' ? 'Selecione o filtro' : agentPostsName;
    let selectArrowToggle = selectToggle ? 'selectArrowOpen' : '';

    const handleToggle = () => {
        setSelectToggle(false)
    }

    const ref = useDetectClickOutside({ onTriggered: handleToggle })

    return (
        <div className={`selectAgent`} ref={ref}>
            {/* Selecionado */}
        <div className={`selectHeader ${selectState} ${headerTheme}`} onClick={()=>setSelectToggle(!selectToggle)}>
            <span>{selectPlaceholder}</span>
            {theme ? <IconSetaDark className={`selectArrow ${selectArrowToggle}`} /> : <IconSetaLight className={`selectArrow ${selectArrowToggle}`} />}
        </div>
        {/* Opções */}
        <div className={`selectOption ${optionToggle} ${optionTheme}`}>
            <div value='content_id' name='ID' onClick={handleAgent}>
                <span value='content_id' name='ID' onClick={handleAgent}>ID</span>
            </div>
            <div value='group_id' name='ID do Grupo' onClick={handleAgent}>
                <span value='group_id' name='ID do Grupo' onClick={handleAgent}>ID do Grupo</span>
            </div>
            <div value='user_id' name='ID do Usuário' onClick={handleAgent}>
                <span value='user_id' name='ID do Usuário' onClick={handleAgent}>ID do Usuário</span>
            </div>
            <div value='group_name' name='Grupo' onClick={handleAgent}>
                <span value='group_name' name='Grupo' onClick={handleAgent}>Grupo</span>
            </div>
            <div value='group_privacy' name='Privacidade' onClick={handleAgent}>
                <span value='group_privacy' name='Privacidade' onClick={handleAgent}>Privacidade</span>
            </div>
            <div value='user_name' name='Usuário' onClick={handleAgent}>
                <span value='user_name' name='Usuário' onClick={handleAgent}>Usuário</span>
            </div>
            <div value='content' name='Conteúdo' onClick={handleAgent}>
                <span value='content' name='Conteúdo' onClick={handleAgent}>Conteúdo</span>
            </div>
            <div value='verb' name='Ação' onClick={handleAgent}>
                <span value='verb' name='Ação' onClick={handleAgent}>Ação</span>
            </div>
        </div>
    </div>
  )
}

export default SelectAgent