import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { firebaseAuth, setImgStorage } from '../Services/firebase'

import '../assets/css/pages/EditProfile.css';
import { GeneralContext } from '../context/GeneralContext';
import { Loading } from '../components/atoms/Loading';

import MainMenu from '../components/organisms/MainMenu'
import Title from '../components/atoms/Title'

import { setProfile } from '../api/api'

const EditProfile = (props) => {
  const { theme, toggleMenu, userName, setUrlImage, setUserName } = useContext(GeneralContext)
  const { setIsAuth } = useContext(GeneralContext)

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const urlImage = localStorage.getItem('url');

  //Variáveis de CSS
  const contentPageToggle = toggleMenu ? 'contentPageOpen' : 'contentPageClose';
  const contentPageTheme = theme ? 'contentPageDark' : 'contentPageLight';
  const editProfilePageTheme = theme ? 'editProfilePageDark' : 'editProfilePageLight';
  const backgroundTheme = theme ? 'backgroundDark' : 'backgroundLight';
  const dropImageTheme = theme ? 'dropImageDark' : 'dropImageLight';

  const navigate = useNavigate();

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        setIsAuth(true)
      } else {
        setIsAuth(false)
        navigate('/')
      }
    })
  }, [props.history, navigate, setIsAuth])

  //Funções
  const handleUserName = (e) => {
    setUserName(e.target.value)
  }

  const editValues = async () => {
    if (file) {
      setIsLoading(true)
      const res = await setImgStorage(file)
      localStorage.setItem('url', res);
      setUrlImage(res)
      if (urlImage && userName) {
        const res = await setProfile(userName, urlImage)
        if (res.data) {
          setIsLoading(false)
          window.location.reload()
        }
      }
    }
    else {
      setIsLoading(true)
      const res = await setProfile(userName, urlImage)
      if (res.data) {
        setIsLoading(false)
        window.location.reload()
      }
    }
  }

  const handlePreview = async (event) => {
    setFile(event.target.files[0]);
    if (window.FileReader) {
      const fileImg = new FileReader();
      fileImg.onload = function (event) {
        document.getElementById("imgUser").src = event.target.result;
      };
      fileImg.readAsDataURL(event.target.files[0]);
    }
  }

  return (
    <>
      <div className='editProfile'>
        <div className={`${backgroundTheme}`} />
        <MainMenu />
        <div className={`contentPage ${contentPageToggle} ${contentPageTheme}`}>
          <Title />
          {isLoading && <Loading />}
          <div className={`editProfileContent ${editProfilePageTheme}`}>
            <span>Nome do usuário</span>
            <input className='inputName' type='text' placeholder='nome' value={userName} onChange={handleUserName}></input>
            {file !== null ? <img className='userProfile' id="imgUser" src={file} alt="Foto do usuário" /> : <img className='userProfile' id="imgUser" src={`${urlImage}`} alt="Foto do usuário" />}
            <label className={`${dropImageTheme} dropImage`} for='userImage'>
              <span>Selecionar imagem</span>
            </label>
            <input className='displayNone' type='file' onChange={e => handlePreview(e)} id='userImage' accept='image/*' />
            <button onClick={editValues}>Salvar</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;