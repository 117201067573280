import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import '../assets/css/pages/Login.css';
import { GeneralContext } from '../context/GeneralContext';

import { getInfos } from '../api/api'

import LogoAlest from '../assets/img/LogoAlest.svg';
import LogoEdiscovery from '../assets/img/LogoEdiscovery.svg';

import IconPasswordOn from '../assets/img/icons/lightmode/IconPasswordOn.svg';
import IconPasswordOff from '../assets/img/icons/lightmode/IconPasswordOff.svg';

import { Loading } from '../components/atoms/Loading';

import { firebaseAuth, login } from '../Services/firebase'

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import domain from '../config/domain.json'

const Login = (props) => {

  const { setIsAuth, setUrlImage, setUserName } = useContext(GeneralContext)

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const setValuesProfile = async () => {
    localStorage.clear();
    const res = await getInfos(`general/profile`)
    localStorage.setItem('auth', true);
    localStorage.setItem('url', res.data[1]);
    localStorage.setItem('user', res.data[0]);
    setUserName(res.data[0])
    setUrlImage(res.data[1])
    redirectPage()
  }

  const redirectPage = () => {
    navigate('/pagina-inicial')
  }

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        setIsAuth(true)
        setValuesProfile()
      } else {
        navigate('/')
      }
    })
  }, [props.history])

  const handleEmail = (event) => {
    setEmail(event.target.value)
  }

  const handlePassword = (event) => {
    setPassword(event.target.value)
  }

  const signIn = async (e) => {
    e.preventDefault()
    try {
      if (email.split("@")[1] === "alest.com.br" || email.split("@")[1] === domain.domain) {
        setIsLoading(true);
        await login(email, password)
        firebaseAuth.onAuthStateChanged(user => {
          if (user) {
            setIsAuth(true)
            setValuesProfile()
          }
        })
      } else {
        toast.error('Credenciais incorretas. Por favor, tente novamente!', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err)
      toast.error('Credenciais incorretas. Por favor, tente novamente!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  return (
    <div className="login">
      {isLoading && <Loading />}
      <ToastContainer />
      <div className='logoAlest'>
        <img src={LogoAlest} alt='Logo da Alest' />
      </div>
      <div className='logoEdiscovery'>
        <img src={LogoEdiscovery} alt='Logo do Infobot' />
      </div>
      <div className='loginField'>
        <span>Login</span>
        <input type='email' placeholder='E-mail' onChange={handleEmail} value={email} />
        <div className='boxPassword'>
          <input type={showPassword ? 'text' : 'password'} placeholder='Senha' onChange={handlePassword} value={password} />
          {showPassword ?
            <img className='passwordShow' src={IconPasswordOn} alt="Ícone de password off" onClick={() => setShowPassword(!showPassword)} />
            :
            <img className='passwordShow' src={IconPasswordOff} alt="Ícone de password on" onClick={() => setShowPassword(!showPassword)} />
          }
        </div>
        <button onClick={signIn}>Entrar</button>
      </div>
      <div className='productDescription'>
        <span>Visualize seus relatórios do jeito que quiser e a qualquer horário</span>
      </div>
    </div>
  );
};

export default Login;