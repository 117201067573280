import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import '../../assets/css/components/Title.css';
import { GeneralContext } from '../../context/GeneralContext';

const Title = () => {

  const { theme } = useContext(GeneralContext)

  //Variáveis
  let location = useLocation();
  let title = '';
  let path = location.pathname;

  //Funções
  switch (path) {
    case '/pagina-inicial':
      title = 'Página inicial';
      break;
    case '/dashboard':
      title = 'Dashboard';
      break;
    case '/atividades':
      title = 'Atividades';
      break;
    case '/conversas':
      title = 'Conversas';
      break;
    case '/auditores':
      title = 'Auditores';
      break;
    case '/alertas':
      title = 'Alertas';
      break;
    case '/editar-perfil':
      title = 'Editar Perfil';
      break;
    default:
      return 0;
  };

  //Variáveis de CSS
  const titleTheme = theme ? 'titleDark' : 'titleLight';

  return (
    <div className={`title ${titleTheme}`}>
      <span className='productTitle'>eDiscovery |</span>
      <span className='pageTitle'>{title}</span>
    </div>
  );
};

export default Title;
