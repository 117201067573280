import React, { useState, useContext } from 'react'

import '../../assets/css/components/SelectAgent.css';
import { GeneralContext } from '../../context/GeneralContext';

import { ReactComponent as IconSetaDark} from '../../assets/img/icons/darkmode/IconSeta.svg'

import { ReactComponent as IconSetaLight} from '../../assets/img/icons/lightmode/IconSeta.svg'

import { useDetectClickOutside } from 'react-detect-click-outside';

const SelectAgent = (props) => {

    const { theme, setAgentComments, agentCommentsName, setAgentCommentsName } = useContext(GeneralContext);

    // Variáveis de estado
    const [selectToggle, setSelectToggle] = useState(false);

    // Variáveis de tema
    const headerTheme = theme ? 'selectHeaderDark' : 'selectHeaderLight';
    const optionTheme = theme ? 'selectOptionDark' : 'selectOptionLight';

    // Funções
    const handleAgent = (e) => {
        setAgentComments(e.target.getAttribute('value'));
        setAgentCommentsName(e.target.getAttribute('name'));
        setSelectToggle(!selectToggle);
    }

    // Variáveis de Condições
    let selectState = selectToggle ? 'selectOpen' : 'selectClose';
    let optionToggle = selectToggle ? '' : 'selectOptionHidden';
    let selectPlaceholder = agentCommentsName === '' ? 'Selecione o filtro' : agentCommentsName;
    let selectArrowToggle = selectToggle ? 'selectArrowOpen' : '';

    const handleToggle = () => {
        setSelectToggle(false)
    }

    const ref = useDetectClickOutside({ onTriggered: handleToggle })

    return (
        <div className={`selectAgent`} ref={ref}>
            {/* Selecionado */}
            <div className={`selectHeader ${selectState} ${headerTheme}`} onClick={()=>setSelectToggle(!selectToggle)}>
                <span>{selectPlaceholder}</span>
                {theme ? <IconSetaDark className={`selectArrow ${selectArrowToggle}`} /> : <IconSetaLight className={`selectArrow ${selectArrowToggle}`} />}
            </div>
            {/* Opções */}
            <div className={`selectOption ${optionToggle} ${optionTheme}`}>
                <div value='comment_id' name='ID' onClick={handleAgent}>
                    <span value='comment_id' name='ID' onClick={handleAgent}>ID</span>
                </div>
                <div value='from_name' name='Usuário' onClick={handleAgent}>
                    <span value='from_name' name='Usuário' onClick={handleAgent}>Usuário</span>
                </div>
                <div value='comment' name='Comentário' onClick={handleAgent}>
                    <span value='comment' name='Comentário' onClick={handleAgent}>Comentário</span>
                </div>
                <div value='verb' name='Ação' onClick={handleAgent}>
                    <span value='verb' name='Ação' onClick={handleAgent}>Ação</span>
                </div>
                <div value='community_id' name='ID da Comunidade' onClick={handleAgent}>
                    <span value='community_id' name='ID da Comunidade' onClick={handleAgent}>ID da Comunidade</span>
                </div>
                <div value='post_id' name='ID da Postagem' onClick={handleAgent}>
                    <span value='post_id' name='ID da Postagem' onClick={handleAgent}>ID da Postagem</span>
                </div>
                <div value='from_id' name='ID do Usuário' onClick={handleAgent}>
                    <span value='from_id' name='ID do Usuário' onClick={handleAgent}>ID do Usuário</span>
                </div>
            </div>
        </div>
    )
}

export default SelectAgent