import React, { useState, useContext } from 'react'

import '../../assets/css/components/SelectAgent.css';
import { GeneralContext } from '../../context/GeneralContext';

import { ReactComponent as IconSetaDark} from '../../assets/img/icons/darkmode/IconSeta.svg'

import { ReactComponent as IconSetaLight} from '../../assets/img/icons/lightmode/IconSeta.svg'

import { useDetectClickOutside } from 'react-detect-click-outside';

const SelectAgent = (props) => {

    const { theme, setAgentFeed, agentFeedName, setAgentFeedName } = useContext(GeneralContext);

    // Variáveis de estado
    const [selectToggle, setSelectToggle] = useState(false);

    // Variáveis de tema
    const headerTheme = theme ? 'selectHeaderDark' : 'selectHeaderLight';
    const optionTheme = theme ? 'selectOptionDark' : 'selectOptionLight';

    // Funções
    const handleAgent = (e) => {
        setAgentFeed(e.target.getAttribute('value'));
        setAgentFeedName(e.target.getAttribute('name'));
        setSelectToggle(!selectToggle);
    }

    // Variáveis de Condições
    let selectState = selectToggle ? 'selectOpen' : 'selectClose';
    let optionToggle = selectToggle ? '' : 'selectOptionHidden';
    let selectPlaceholder = agentFeedName === '' ? 'Selecione o filtro' : agentFeedName;
    let selectArrowToggle = selectToggle ? 'selectArrowOpen' : '';

    const handleToggle = () => {
        setSelectToggle(false)
    }

    const ref = useDetectClickOutside({ onTriggered: handleToggle })

    return (
        <div className={`selectAgent`} ref={ref}>
            {/* Selecionado */}
        <div className={`selectHeader ${selectState} ${headerTheme}`} onClick={()=>setSelectToggle(!selectToggle)}>
            <span>{selectPlaceholder}</span>
            {theme ? <IconSetaDark className={`selectArrow ${selectArrowToggle}`} /> : <IconSetaLight className={`selectArrow ${selectArrowToggle}`} />}
        </div>
        {/* Opções */}
        <div className={`selectOption ${optionToggle} ${optionTheme}`}>
            <div value='user_id' name='ID' onClick={handleAgent}>
                <span value='user_id' name='ID' onClick={handleAgent}>ID</span>
            </div>
            <div value='user_name' name='Nome' onClick={handleAgent}>
                <span value='user_name' name='Nome' onClick={handleAgent}>Nome</span>
            </div>
            <div value='author_name' name='Autor' onClick={handleAgent}>
                <span value='author_name' name='Autor' onClick={handleAgent}>Autor</span>
            </div>
            <div value='message' name='Mensagem' onClick={handleAgent}>
                <span value='message' name='Mensagem' onClick={handleAgent}>Mensagem</span>
            </div>
            <div value='verb' name='Ação' onClick={handleAgent}>
                <span value='verb' name='Ação' onClick={handleAgent}>Ação</span>
            </div>
            <div value='author_id' name='ID do Autor' onClick={handleAgent}>
                <span value='author_id' name='ID do Autor' onClick={handleAgent}>ID do Autor</span>
            </div>
        </div>
    </div>
  )
}

export default SelectAgent