import React, { useState, useContext } from 'react'

import '../../assets/css/components/FormAuditors.css';
import { GeneralContext } from '../../context/GeneralContext';

import { setAuditor } from '../../api/api';

import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const FormAuditors = (props) => {

    const { theme } = useContext(GeneralContext);

    //Variáveis de estado
    const [formData, setFormData] = useState({
        email: '',
        phoneNumber: ''
    })
    const [roles, setRoles] = useState([])
    const [alertTheme, setAlertTheme] = useState(theme ? "dark" : "light")

    // Variáveis de tema
    const textColorTheme = theme ? 'textColorDark' : 'textColorLight';
    const inputTextTheme = theme ? 'inputTextDark' : 'inputTextLight';


    //Funções
    const handleFormSubmit = e => {
        e.preventDefault()
        createAuditor()
    }

    const createAuditor = async () => {
        const { email, phoneNumber } = formData
        if (!email || !phoneNumber) {
            setAlertTheme(theme ? "dark" : "light")
            toast.error('Insira o email e o número de telefone do auditor para criá-lo!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: `${alertTheme}`,
            });
            return
        }

        try {
            const res = await setAuditor(email, phoneNumber, roles).catch(res => res.response)
            if (res.status >= 400) {
                console.log(res)
                setAlertTheme(theme ? "dark" : "light")
                toast.error('Você não tem permissão para criar novos usuários ou o domínio informado é inválido!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: `${alertTheme}`,
                });
            } else {
                setAlertTheme(theme ? "dark" : "light")
                toast.success('Criado com sucesso!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: `${alertTheme}`,
                });
                window.location.reload()
            }

        } catch (err) {
            alert(err)
        }
    }

    const handleInputChange = e => {
        const { name, value } = e.target

        setFormData({ ...formData, [name]: value })
    }

    const handleRadioChange = e => {
        const { value } = e.target

        setRoles([value])
    }

    const handleCheckChange = e => {
        const { value, checked } = e.target

        if (checked) {
            const filteredArr = roles.filter(role => role !== "owner" && role !== "admin" && role !== "viewer")
            filteredArr.push(value)
            setRoles(filteredArr)
        } else {
            setRoles(roles.filter(role => role !== value))
        }
    }

    const isChecked = value => {
        return roles.filter(role => role === value).length > 0
    }

    const disabledCheckbox = () => {
        document.getElementById('viwerWorkchat').disabled = true;
        document.getElementById('viwerComments').disabled = true;
        document.getElementById('viwerFeedComments').disabled = true;
        document.getElementById('viwerFeed').disabled = true;
        document.getElementById('viwerPosts').disabled = true;
    }

    const enableCheckbox = () => {
        document.getElementById('viwerWorkchat').disabled = false;
        document.getElementById('viwerComments').disabled = false;
        document.getElementById('viwerFeedComments').disabled = false;
        document.getElementById('viwerFeed').disabled = false;
        document.getElementById('viwerPosts').disabled = false;
    }

    return (
        <>
            <div className='formAuditors'>
                <div className={`createAuditors ${textColorTheme}`}>
                    <span>Criar perfil de auditor</span>
                </div>
                <form>
                    <div className='auditorsData'>
                        <input type='email' name='email' className={`emailAuditors ${inputTextTheme}`} placeholder='Email' value={formData.email} onChange={handleInputChange} />
                        <input type='text' name='phoneNumber' className={`phoneAuditors ${inputTextTheme}`} placeholder='Número de telefone (+55999999999)' value={formData.phoneNumber} onChange={handleInputChange} />
                    </div>
                    <div className='auditorsPermission'>
                        <div className={`permissionList ${textColorTheme}`}>
                            <span>Permissões</span>
                            <div className={`optionPermission ${textColorTheme}`}>
                                <input onClick={() => disabledCheckbox()} checked={isChecked("owner")} value='owner' onChange={handleRadioChange} type='radio' id='owner' name='auditorspermissions' />
                                <label htmlFor='owner'>Proprietário</label>
                            </div>
                            <div className={`optionPermission ${textColorTheme}`}>
                                <input onClick={() => disabledCheckbox()} checked={isChecked("admin")} value='admin' onChange={handleRadioChange} type='radio' id='admin' name='auditorspermissions' />
                                <label htmlFor='admin'>Administrador</label>
                            </div>
                            <div className={`optionPermission ${textColorTheme}`}>
                                <input onClick={() => enableCheckbox()} checked={isChecked("viewer")} value='viewer' onChange={handleRadioChange} type='radio' id='viewer' name='auditorspermissions' />
                                <label htmlFor='viewer'>Visualizador</label>
                            </div>
                        </div>
                        <div className={`permissionList ${textColorTheme}`}>
                            <span>Visualizadores específicos</span>
                            <div className={`optionPermission ${textColorTheme}`}>
                                <input type='checkbox' id='viwerWorkchat' value='workchat.viewer' checked={isChecked("workchat.viewer")} onChange={handleCheckChange} />
                                <label htmlFor='viwerWorkchat'>Visualizador do workchat</label>
                            </div>
                            <div className={`optionPermission ${textColorTheme}`}>
                                <input type='checkbox' id='viwerComments' value='comments.viewer' checked={isChecked("comments.viewer")} onChange={handleCheckChange} />
                                <label htmlFor='viwerComments'>Visualizador dos comentários</label>
                            </div>
                            <div className={`optionPermission ${textColorTheme}`}>
                                <input type='checkbox' id='viwerFeedComments' value='feed_comments.viewer' checked={isChecked("feed_comments.viewer")} onChange={handleCheckChange} />
                                <label htmlFor='viwerFeedComments'>Visualizador dos comentários do feed</label>
                            </div>
                            <div className={`optionPermission ${textColorTheme}`}>
                                <input type='checkbox' id='viwerFeed' value='feed.viewer' checked={isChecked("feed.viewer")} onChange={handleCheckChange} />
                                <label htmlFor='viwerFeed'>Visualizador do feed</label>
                            </div>
                            <div className={`optionPermission ${textColorTheme}`}>
                                <input disabled={false} type='checkbox' id='viwerPosts' value='posts.viewer' checked={isChecked("posts.viewer")} onChange={handleCheckChange} />
                                <label htmlFor='viwerPosts'>Visualizador dos posts</label>
                            </div>
                        </div>
                    </div>
                    <input type='submit' className='submitAuditors' value='Criar' onClick={handleFormSubmit} />
                </form>
            </div>
        </>
    )
}

export default FormAuditors