import React, { useState, useEffect, useContext } from "react";

import "../../assets/css/components/Tables/WorkchatTable.css";
import { GeneralContext } from "../../context/GeneralContext";

import WorkchatDetail from "../atoms/DetailsModal/WorkchatDetail";

import { ReactComponent as IconVisualizarDark } from "../../assets/img/icons/darkmode/IconVisualizar.svg";
import { ReactComponent as IconVisualizarLight } from "../../assets/img/icons/lightmode/IconVisualizar.svg";

import { ReactComponent as IconArrowDark } from "../../assets/img/icons/darkmode/IconArrow.svg";

import { getInfos } from "../../api/api";

import { Loading } from '../atoms/Loading.js';

const WorkchatTable = (props) => {
  const { theme, company, setPageCurrent, isFilter, totRows, setTotRows} = useContext(GeneralContext);

  const [viewDetail, setViewDetail] = useState(false);
  const [idRow, setIdRow] = useState("");
  const [row, setRow] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1)
  const [offSet, setOffSet] = useState(0)
  const [auxPage, setAuxPage] = useState(0)

  // Variáveis de tema
  const tableThTheme = theme ? "tableTdDark" : "tableTdLight";

  useEffect(() => {
    if(company !== 'Selecione uma empresa'){
      (async () => {
        setIsLoading(true);
        const res = await getInfos(`general/workchat`, offSet, "created_time");
        if (res.data !== undefined) {
          res.data.forEach((dash, index) => {
            if (dash.community_id === company) {
              setRow(res.data[index].data.rows)
              setTotRows(dash.data.totalResults)
            }
            index++;
          });
          setIsLoading(false);
        }
      })();
    }
  }, [company]);

  useEffect(() => {
    setRow(props.rowFilter);
  }, [props.rowFilter]);

  useEffect(() => {
    if (auxPage < offSet) {
      if (isFilter === false) {
        nextPage()
        setAuxPage(offSet)
      }
      else {
        setAuxPage(offSet)
        setPageCurrent(offSet)
        setCurrentPage(offSet / 10 + 1)
      }
    }
    if (auxPage > offSet) {
      if (isFilter === false) {
        previousPage()
        setAuxPage(offSet)
      }
      else {
        setAuxPage(offSet)
        setPageCurrent(offSet)
        setCurrentPage((offSet / 10 + 1))
      }
    }
  }, [offSet])

  const nextPage = async () => {
    setIsLoading(true);
    setCurrentPage(currentPage + 1)
    const res = await getInfos(`general/workchat`, offSet, "created_time");
    if (res.data !== undefined) {
      res.data.forEach((dash, index) => {
        if (dash.community_id === company) {
          setRow(res.data[index].data.rows)
          setTotRows(dash.data.totalResults)
        }
        index++;
      });
    }
    setIsLoading(false);
  };

  const previousPage = async () => {
    setIsLoading(true);
    setCurrentPage(currentPage - 1)
    const res = await getInfos("general/workchat", offSet, "created_time");
    if (res.data !== undefined) {
      res.data.forEach((dash, index) => {
        if (dash.community_id === company) {
          setRow(res.data[index].data.rows);
          setTotRows(dash.data.totalResults)
        }
        index++;
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="pagination">
        <button onClick={() => {
          if (currentPage !== 1) {
            setOffSet(offSet - 10)
          }
        }
        }>
          <IconArrowDark className="arrowPage previousArrow" />
        </button>
        <span>{currentPage}</span>
        <button onClick={() => {
          if (totRows / 10 > currentPage) {
            setOffSet(offSet + 10)
          }
        }
        }>
          <IconArrowDark className="arrowPage nextArrow" />
        </button>
      </div>
      <table className={`tableActivity`}>
        <thead>
          <tr>
            <th className="creationWorkchat">CRIAÇÃO</th>
            <th className="authorWorkchat">AUTOR</th>
            <th className="userWorkchat">USUÁRIO</th>
            <th className="messageWorkchat">MENSAGEM</th>
            <th className="viewWorkchat"></th>
          </tr>
        </thead>
        <tbody>
          {row ? (
            row.map((rows, key) => {
              return (
                <tr key={key} className={`${tableThTheme}`}>
                  <td>
                    <span>{rows.created_time}</span>
                  </td>
                  <td>
                    <span>{rows.from_name}</span>
                  </td>
                  <td>
                    <span>{rows.to_name}</span>
                  </td>
                  <td>
                    <span>{rows.message}</span>
                  </td>
                  <td>
                    {theme ? (
                      <IconVisualizarDark
                        className="iconTable"
                        onClick={() => {
                          setViewDetail(true);
                          setIdRow(rows.id);
                        }}
                      />
                    ) : (
                      <IconVisualizarLight
                        className="iconTable"
                        onClick={() => {
                          setViewDetail(true);
                          setIdRow(rows.id);
                        }}
                      />
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr></tr>
          )}
        </tbody>
      </table>
      {viewDetail && (
        <WorkchatDetail row={row} setViewDetail={setViewDetail} id={idRow} />
      )}
    </>
  );
};

export default WorkchatTable;
