import React from 'react';

import './App.css';
import RoutesApp from './RoutesApp.js'

function App() {
  return (
    <>
      <>
        <RoutesApp />
      </>
    </>
  );
}

export default App;
