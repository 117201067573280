import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { firebaseAuth } from '../Services/firebase'

import '../assets/css/pages/Alerts.css';
import { GeneralContext } from '../context/GeneralContext';

import MainMenu from '../components/organisms/MainMenu'
import Title from '../components/atoms/Title';
import AlertsTable from '../components/atoms/AlertsTable';

import { getWordsAlert, setWordsAlert, deleteWordsAlert, setAdminAlert } from '../api/api'

// Ícones modo escuro
import { ReactComponent as IconCloseDark } from '../assets/img/icons/darkmode/IconFechar.svg';

// Ícones modo escuro
import { ReactComponent as IconCloseLight } from '../assets/img/icons/lightmode/IconFechar.svg';

const Alerts = (props) => {

  const { theme, toggleMenu, setIsAuth, isAuth } = useContext(GeneralContext);

  const navigate = useNavigate();

  //Variáveis de estado
  const [words, setWords] = useState([]);
  const [word, setWord] = useState([]);
  const [admin, setAdmin] = useState();
  const [permission, setPermission] = useState(false)

  //Variáveis de CSS
  const contentPageToggle = toggleMenu ? 'contentPageOpen' : 'contentPageClose';
  const contentPageTheme = theme ? 'contentPageDark' : 'contentPageLight';
  const backgroundTheme = theme ? 'backgroundDark' : 'backgroundLight';
  const inputWordsTheme = theme ? 'inputWordsDark' : 'inputWordsLight';
  const inputUserTheme = theme ? 'inputWordsDark' : 'inputWordsLight';
  const backgroundBoxTheme = theme ? 'backgroundBoxDark' : 'backgroundBoxLight';
  const wordsBoxItemTheme = theme ? 'wordsBoxItemDark' : 'wordsBoxItemLight';
  const titlePageTheme = theme ? 'titlePageDark' : 'titlePageLight'

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        setIsAuth(true)
        setPermission(true)
      } else {
        setIsAuth(false)
        navigate('/')
      }
    })
  }, [props.history])

  useEffect(() => {
    if (isAuth) {
      (async () => {
        const res = await getWordsAlert();
        setWords(res.data.words)
      })()
    }
  }, [permission])

  //Funções  
  const handleWord = (e) => {
    setWord(e.target.value)
  }

  const handleAdmin = (e) => {
    setAdmin(e.target.value)
  }

  const addWordsAlert = async () => {
    if (word.split(",").length > 1) {
      const arr = word.split(",");
      await setWordsAlert(arr)
      window.location.reload()
    } else {
      await setWordsAlert([word])
      window.location.reload()
    }
  }
  const addAdminAlert = async () => {
    await setAdminAlert([admin])
    window.location.reload()
  }

  const deleteWord = async (word) => {
    await deleteWordsAlert(word)
    window.location.reload()
  }

  return (
    <div className='alerts'>
      <div className={`${backgroundTheme}`} />
      <MainMenu />
      <div className={`contentPage ${contentPageToggle} ${contentPageTheme}`}>
        <Title />
        <div className='boxContent'>
          <div className='firstContent'>
            <span className={`titlePage ${titlePageTheme}`}>Palavras sensíveis</span>
            <div className={`wordsBox ${backgroundBoxTheme}`}>
              {
                words.map((word) => {
                  return (
                    <div className={`wordsBoxItem ${wordsBoxItemTheme}`}>
                      <span>{word}</span>
                      {theme ? <IconCloseDark className='iconClose' onClick={() => deleteWord(word)} /> : <IconCloseLight className='iconClose' />}
                    </div>
                  )
                })
              }
            </div>
            <input type='text' value={word} onChange={handleWord} className={`inputWords ${inputWordsTheme}`} placeholder='Adicionar palavra' />
            <button onClick={addWordsAlert}>Adicionar</button>
          </div>
          <div className='secondContent'>
            <AlertsTable />
            <input type='text' value={admin} onChange={handleAdmin} className={`inputUser ${inputUserTheme}`} placeholder='Cadastrar admin' />
            <button onClick={addAdminAlert}>Cadastrar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alerts;
