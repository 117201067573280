import React, { useState, useContext } from 'react'

import { useDetectClickOutside } from 'react-detect-click-outside';

import '../../assets/css/components/SelectCompany.css'
import { GeneralContext } from '../../context/GeneralContext'

import { ReactComponent as IconSetaDark } from '../../assets/img/icons/darkmode/IconSeta.svg'

import { ReactComponent as IconSetaLight } from '../../assets/img/icons/lightmode/IconSeta.svg'

const SelectCompany = (props) => {

    const { theme, setCompany, loader, setLoader } = useContext(GeneralContext);

    // Variáveis de estado
    const [selectToggle, setSelectToggle] = useState(false);

    // Variáveis de Condições
    let selectState = selectToggle ? 'selectOpen' : 'selectClose';
    let optionToggle = selectToggle ? '' : 'selectOptionHidden';
    let selectArrowToggle = selectToggle ? 'selectArrowOpen' : '';

    // Variáveis de tema
    const headerTheme = theme ? 'selectHeaderDark' : 'selectHeaderLight';
    const optionTheme = theme ? 'selectOptionDark' : 'selectOptionLight';

    // Funções
    const handleCompany = (e) => {
        setCompany(e.target.getAttribute('value'));
        setSelectToggle(false)
        setLoader(!loader)
    }

    const handleToggle = () => {
        setSelectToggle(false)
    }

    const ref = useDetectClickOutside({ onTriggered: handleToggle })

    return (
        <div className={`selectCompany`} ref={ref}>
            {/* Selecionado */}
            <div className={`selectHeader ${selectState} ${headerTheme}`} onClick={() => setSelectToggle(!selectToggle)}>
                <span>{props.nameDashboard}</span>
                {theme ? <IconSetaDark className={`selectArrow ${selectArrowToggle}`} /> : <IconSetaLight className={`selectArrow ${selectArrowToggle}`} />}
            </div>
            {/* Opções */}
            <div className={`selectOption ${optionToggle} ${optionTheme}`}>
                {props.dashboards.map((dash, index) => (
                    <div key={index} id={dash.community_name} value={dash.community_id} onClick={handleCompany}>
                        <option key={dash.community_id} value={dash.community_id}>
                            {dash.community_name}
                        </option>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SelectCompany