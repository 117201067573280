import React, { useContext } from 'react';

import { useNavigate } from 'react-router-dom'

import '../../assets/css/components/UserMenu.css'
import { GeneralContext } from '../../context/GeneralContext';

import Toggle from '../atoms/Toggle.js'
import {firebaseAuth} from "../../Services/firebase";

import { ReactComponent as IconLuzDark } from '../../assets/img/icons/darkmode/IconLuz.svg';
import { ReactComponent as IconSairDark } from '../../assets/img/icons/darkmode/IconSair.svg';
import { ReactComponent as IconPerfilDark } from '../../assets/img/icons/darkmode/IconPerfil.svg';

import { ReactComponent as IconLuzLight } from '../../assets/img/icons/lightmode/IconLuz.svg';
import { ReactComponent as IconSairLight } from '../../assets/img/icons/lightmode/IconSair.svg';
import { ReactComponent as IconPerfilLight } from '../../assets/img/icons/lightmode/IconPerfil.svg';

const UserMenu = () => {

    const { setIsAuth, theme, setTheme, toggleMenu } = useContext(GeneralContext);

    const navigate = useNavigate();

    const logout = async () => {
        firebaseAuth.signOut();
        setIsAuth(false)
        localStorage.clear();
        navigate("/")
    }

    const toggleUserMenu = toggleMenu ? 'userMenuOpen' : 'userMenuClose';
    const userMenuTheme = theme ? 'userMenuDark' : 'userMenuLight';
    const userOptionTheme = theme ? 'userOptionDark' : 'userOptionLight';

    return (
        <div className={`userMenu ${userMenuTheme} ${toggleUserMenu}`}>
            <div className={`${userOptionTheme}`}>
                {theme ? <IconLuzDark className='iconUser' /> : <IconLuzLight className='iconUser' />}
                <span>Modo escuro</span>
                <div className='toggleTheme'>
                    <Toggle setTheme={setTheme} theme={theme} />
                </div>
            </div>
            <div className={`${userOptionTheme}`} onClick={() => { navigate('/editar-perfil')}}>
                {theme ? <IconPerfilDark className='iconUser' /> : <IconPerfilLight className='iconUser' />}
                <span>Editar perfil</span>
            </div>
            <div className={`${userOptionTheme}`} onClick={() => { logout() }}>
                {theme ? <IconSairDark className='iconUser' /> : <IconSairLight className='iconUser' />}
                <span>Sair</span>
            </div>
        </div>
    );
}

export default UserMenu