import React, { useState, useEffect, useContext } from "react";

import "../../assets/css/components/Tables/CommentsTable.css";
import { GeneralContext } from "../../context/GeneralContext";

import CommentsDetail from "../atoms/DetailsModal/CommentsDetail";

import { ReactComponent as IconVisualizarDark } from "../../assets/img/icons/darkmode/IconVisualizar.svg";
import { ReactComponent as IconVisualizarLight } from "../../assets/img/icons/lightmode/IconVisualizar.svg";

import { ReactComponent as IconArrowDark } from "../../assets/img/icons/darkmode/IconArrow.svg";

import { getInfos } from "../../api/api";

import { Loading } from "../atoms/Loading";

const CommentsTable = (props) => {
  const { theme, company, setPageCurrent, isFilter, totRows, setTotRows } = useContext(GeneralContext);

  // Variáveis de estado
  const [viewDetail, setViewDetail] = useState(false);
  const [idRow, setIdRow] = useState("");
  const [row, setRow] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1)
  const [offSet, setOffSet] = useState(0)
  const [auxPage, setAuxPage] = useState(0)

  // Variáveis de tema
  const tableThTheme = theme ? "tableTdDark" : "tableTdLight";

  useEffect(() => {
    if(company !== 'Selecione uma empresa'){
      (async () => {
        setIsLoading(true)
        const res = await getInfos(`general/comments`, offSet, "created_time");
        if (res.data !== undefined) {
          res.data.forEach((dash, index) => {
            if (dash.community_id === company) {
              setRow(res.data[index].data.rows)
              setTotRows(dash.data.totalResults)
            }
            index++;
          });
        }
        setIsLoading(false);
      })();
    }
  }, [company]);

  useEffect(() => {
    setRow(props.rowFilter);
  }, [props.rowFilter]);

  useEffect(() => {
    if (auxPage < offSet) {
      if (isFilter === false) {
        nextPage()
        setAuxPage(offSet)
      }
      else {
        setAuxPage(offSet)
        setPageCurrent(offSet)
        setCurrentPage(offSet / 10 + 1)
      }
    }
    if (auxPage > offSet) {
      if (isFilter === false) {
        previousPage()
        setAuxPage(offSet)
      }
      else {
        setAuxPage(offSet)
        setPageCurrent(offSet)
        setCurrentPage((offSet / 10 + 1))
      }
    }
  }, [offSet])

  const nextPage = async () => {
    setIsLoading(true);
    setCurrentPage(currentPage + 1)
    const res = await getInfos(`general/comments`, offSet, "created_time");
    if (res.data !== undefined) {
      res.data.forEach((dash, index) => {
        if (dash.community_id === company) {
          setRow(res.data[index].data.rows)
          setTotRows(dash.data.totalResults)
        }
        index++;
      });
    }
    setIsLoading(false);
  };

  const previousPage = async () => {
    setIsLoading(true);
    setCurrentPage(currentPage - 1)
    const res = await getInfos("general/comments", offSet, "created_time");
    if (res.data !== undefined) {
      res.data.forEach((dash, index) => {
        if (dash.community_id === company) {
          setRow(res.data[index].data.rows);
          setTotRows(dash.data.totalResults)
        }
        index++;
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="pagination">
        <button onClick={() => {
          if (currentPage !== 1) {
            setOffSet(offSet - 10)
          }
        }
        }>
          <IconArrowDark className="arrowPage previousArrow" />
        </button>
        <span>{currentPage}</span>
        <button onClick={() => {
          if (totRows / 10 > currentPage) {
            setOffSet(offSet + 10)
          }
        }
        }>
          <IconArrowDark className="arrowPage nextArrow" />
        </button>
      </div>
      <table className={`tableActivity ${tableThTheme}`}>
        <thead>
          <tr>
            <th className="creationComments">CRIAÇÃO</th>
            <th className="idComments">ID</th>
            <th className="userComments">USUÁRIO</th>
            <th className="commentComments">COMENTÁRIO</th>
            <th className="linkComments">LINK</th>
            <th className="viewComments"></th>
          </tr>
        </thead>
        <tbody>
          {row ? (
            row.map((rows, index) => {
              return (
                <tr key={index} className={`${tableThTheme}`}>
                  <td>
                    <span>{rows.created_time}</span>
                  </td>
                  <td>
                    <span>{rows.comment_id}</span>
                  </td>
                  <td>
                    <span>{rows.from_name}</span>
                  </td>
                  <td>
                    <span>{rows.comment}</span>
                  </td>
                  <td>
                    <span>{rows.permalink}</span>
                  </td>
                  <td>
                    {theme ? (
                      <IconVisualizarDark
                        className="iconTable"
                        onClick={() => {
                          setViewDetail(true);
                          setIdRow(rows.comment_id);
                        }}
                      />
                    ) : (
                      <IconVisualizarLight
                        className="iconTable"
                        onClick={() => {
                          setViewDetail(true);
                          setIdRow(rows.comment_id);
                        }}
                      />
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr></tr>
          )}
        </tbody>
      </table>
      {viewDetail && (
        <CommentsDetail row={row} setViewDetail={setViewDetail} id={idRow} />
      )}
    </>
  );
};

export default CommentsTable;