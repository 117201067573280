import React, { useState, useEffect, useContext, useCallback } from 'react'

import '../../assets/css/components/Tables/AlertsTable.css';
import { GeneralContext } from '../../context/GeneralContext';

import { getAdminsAlert, deleteAdminAlert } from '../../api/api'

import { ReactComponent as IconLixeiraHighLight } from '../../assets/img/icons/highlight/IconLixeira.svg';

import {firebaseAuth} from '../../Services/firebase';

const AlertsTable = () => {

    const { theme, isAuth } = useContext(GeneralContext);

    // Variáveis de estado
    const [admins, setAdmins] = useState([]);
    const [hasPermissions, setHasPermissions] = useState();
    const [popUpDelete, setPopUpDelete] = useState(false);
    const [admin, setAdmin] = useState('');

    // Variáveis de tema
    const tableAlertsTheme = theme ? 'tableAlertsDark' : 'tableAlertsLight';
    const popUpTheme = theme ? 'popUpDark' : 'popUpLight';

    // Funções
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadAdmins = useCallback(async () => {
        if(isAuth){
            const res = await getAdminsAlert().catch(err => err.response)
    
            const { data, status } = res;
    
            if (status >= 401) {
                setHasPermissions(false)
                return
            }
            setHasPermissions(true);
            setAdmins(data.admins)
        }
    })

    useEffect(() => {
        (() => {
            firebaseAuth.onAuthStateChanged(user => {
                if(user){
                    loadAdmins()
                }
            })})()
    }, [admin, admins, loadAdmins])

    return (
        <>
            <table className={`tableAlerts ${tableAlertsTheme}`}>
                <thead>
                    <tr>
                        <th className='activeAlerts'>
                            USUÁRIOS EXISTENTES
                        </th>
                        <th className='iconAlerts'>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hasPermissions ?
                        admins.map((user, key) => {
                            return (
                                <tr key={key}>
                                    <td>
                                        <span>
                                            {user}
                                        </span>
                                    </td>
                                    <td>
                                        <div className='iconBoxAlerts'>
                                            <IconLixeiraHighLight className='iconTable' onClick={() => { setPopUpDelete(true); setAdmin(user)}} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr>
                            <td>
                                <span></span>
                            </td>
                            <td>
                                <div className='iconBoxAlerts'>
                                    <IconLixeiraHighLight className='iconTable' />
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            {
                popUpDelete
                &&
                <div className={`popUpDelete ${popUpTheme}`}>
                    <span>Você tem certeza?</span>
                    <div>
                        <div onClick={() => { deleteAdminAlert(admin); setPopUpDelete(false); setAdmin(''); loadAdmins() }} >
                            Sim
                        </div>
                        <div onClick={() => { setPopUpDelete(false); setAdmin(''); loadAdmins() }} >
                            Não
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default AlertsTable