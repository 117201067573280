import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { firebaseAuth } from '../Services/firebase'

import '../assets/css/pages/Welcome.css';
import { GeneralContext } from '../context/GeneralContext';

import MainMenu from '../components/organisms/MainMenu'
import Title from '../components/atoms/Title'

const Welcome = (props) => {

  const { theme, toggleMenu } = useContext(GeneralContext)
  const { setIsAuth } = useContext(GeneralContext)

  //Variáveis de CSS
  const contentPageToggle = toggleMenu ? 'contentPageOpen' : 'contentPageClose';
  const contentPageTheme = theme ? 'contentPageDark' : 'contentPageLight';
  const initialPageTheme = theme ? 'initialPageDark' : 'initialPageLight';
  const backgroundTheme = theme ? 'backgroundDark' : 'backgroundLight';

  const navigate = useNavigate();

  useEffect(() => {
    // FirebaseService.firebaseAuth.onAuthStateChanged(user => {
    firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        setIsAuth(true)
      } else {
        setIsAuth(false)
        navigate('/')
      }
    })
  }, [props.history, navigate, setIsAuth])

  return (
    <>
      <div className='welcome'>
        <div className={`${backgroundTheme}`} />
        <MainMenu />
        <div className={`contentPage ${contentPageToggle} ${contentPageTheme}`}>
          <Title />
          <div className={`initialPageContent ${initialPageTheme}`}>
            <span className='welcome'>Bem-vindo(a) ao eDiscovery</span>
            <span className='welcomeText'>Para acessar as informações gerais clique <Link to='/dashboard' className='linkGeral'>aqui</Link>, caso queira verificar informações mais detalhadas acesse os conteúdos pelo menu principal à esquerda.</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;