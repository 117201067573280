import React, {} from 'react';

import '../../assets/css/components/Toggle.css'

const Toggle = (props) => {

    //Variáveis de CSS
    const toggleTheme = props.theme ? 'toggleDark' : 'toggleLight';
    const circleToggleTheme = props.theme ? 'circleToggleDark' : 'circleToggleLight';
    const toggleOnOff = props.theme ? 'circleToggleOn' : 'circleToggleOff';

    return(
        <div className={`toggle ${toggleTheme}`} onClick={()=>props.setTheme(!props.theme)}>
            <div className={`circleToggle ${toggleOnOff} ${circleToggleTheme}`}></div>
        </div>
    );
}

export default Toggle