import React, { useState, useContext } from 'react'

import '../../assets/css/components/SelectPermission.css';
import { GeneralContext } from '../../context/GeneralContext';

import { ReactComponent as IconSetaDark} from '../../assets/img/icons/darkmode/IconSeta.svg'

import { ReactComponent as IconSetaLight} from '../../assets/img/icons/lightmode/IconSeta.svg'

import { useDetectClickOutside } from 'react-detect-click-outside';

const SelectPermission = (props) => {

    const { theme, permissionName, setPermission, setPermissionName } = useContext(GeneralContext);

    // Variáveis de estado
    const [selectToggle, setSelectToggle] = useState(false);

    // Variáveis de tema
    const headerTheme = theme ? 'selectHeaderDark' : 'selectHeaderLight';
    const optionTheme = theme ? 'selectOptionDark' : 'selectOptionLight';

    // Funções
    const handlePermission = (e) => {
        setPermission(e.target.getAttribute('value'));
        setPermissionName(e.target.getAttribute('name'));
        setSelectToggle(!selectToggle);
    }

    // Variáveis de Condições
    let selectState = selectToggle ? 'selectOpen' : 'selectClose';
    let optionToggle = selectToggle ? '' : 'selectOptionHidden';
    let selectPlaceholder = permissionName === '' ? 'Selecione a permissão' : permissionName;
    let selectArrowToggle = selectToggle ? 'selectArrowOpen' : '';

    const handleToggle = () => {
        setSelectToggle(false)
    }

    const ref = useDetectClickOutside({ onTriggered: handleToggle })
    
  return (
    <div className={`selectPermission`} ref={ref}>
        <div className={`selectHeader ${selectState} ${headerTheme}`} onClick={()=>setSelectToggle(!selectToggle)}>
            <span>{selectPlaceholder}</span>
            {theme ? <IconSetaDark className={`selectArrow ${selectArrowToggle}`} /> : <IconSetaLight className={`selectArrow ${selectArrowToggle}`} />}
        </div>
        <div className={`selectOption ${optionToggle} ${optionTheme}`}>
            <div id='owner' value='owner' name='Proprietário' onClick={handlePermission}>
                <span value='owner' name='Proprietário' onClick={handlePermission}>Proprietário</span>
            </div>
            <div id='admin' value='admin' name='Administrador' onClick={handlePermission}>
                <span value='admin' name='Administrador' onClick={handlePermission}>Administrador</span>
            </div>
            <div id='viewer' value='viewer' name='Visualizador' onClick={handlePermission}>
                <span value='viewer' name='Visualizador' onClick={handlePermission}>Visualizador</span>
            </div>
        </div>
    </div>
  )
}

export default SelectPermission