import React, { useEffect, useContext } from 'react'

import { useNavigate } from 'react-router-dom';

import {firebaseAuth} from '../Services/firebase';

import '../assets/css/pages/Auditors.css';
import { GeneralContext } from '../context/GeneralContext';

import MainMenu from '../components/organisms/MainMenu';
import Title from '../components/atoms/Title';
import AuditorsTable from '../components/molecules/AuditorsTable';
import FormAuditors from '../components/atoms/FormAuditors';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Auditors = (props) => {

  const { theme, toggleMenu, setIsAuth } = useContext(GeneralContext);

  const navigate = useNavigate();

  //Variáveis de estado

  //Variáveis de CSS
  const contentPageToggle = toggleMenu ? 'contentPageOpen' : 'contentPageClose';
  const contentPageTheme = theme ? 'contentPageDark' : 'contentPageLight';
  const backgroundTheme = theme ? 'backgroundDark' : 'backgroundLight';

  //Funções
  useEffect(() => {
    firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        setIsAuth(true)
      } else {
        setIsAuth(false)
        navigate('/')
      }
    })
  }, [props.history])

  return (
    <div className='auditors'>
      <ToastContainer />
      <MainMenu />
      <div className={`contentPage ${contentPageToggle} ${contentPageTheme}`}>
        <div className={`${backgroundTheme}`} />
        <Title />
        <div className='auditorsBox'>
          <FormAuditors />
          <AuditorsTable />
        </div>
      </div>
    </div>
  )
}

export default Auditors