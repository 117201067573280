import React, { useState, useEffect, useContext } from 'react'

import '../../assets/css/components/Tables/AuditorsTable.css';
import { GeneralContext } from '../../context/GeneralContext';

import { getAuditors, deleteUser, editUser } from '../../api/api'

import { ReactComponent as IconLixeiraHighLight } from '../../assets/img/icons/highlight/IconLixeira.svg';
import { ReactComponent as IconEditarHighLight } from '../../assets/img/icons/highlight/IconEditar.svg';

import { ReactComponent as IconSalvarDark } from '../../assets/img/icons/darkmode/IconSalvar.svg';
import { ReactComponent as IconSalvarLight } from '../../assets/img/icons/lightmode/IconSalvar.svg';

import SelectPermission from '../atoms/SelectPermission';

import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import {firebaseAuth} from '../../Services/firebase';

const AuditorsTable = () => {

    const { theme, permission, isAuth } = useContext(GeneralContext);

    // Variáveis de estado
    const [users, setUsers] = useState([])
    const [hasPermissions, setHasPermissions] = useState()
    const [popUpEdit, setPopUpEdit] = useState(false);
    const [popUpDelete, setPopUpDelete] = useState(false);
    const [idUser, setIdUser] = useState('');
    const [roleUser, setRoleUser] = useState([]);
    const [newRolesUser, setNewRolesUser] = useState([]);
    const [alertTheme, setAlertTheme] = useState(theme ? "dark" : "light")

    // Variáveis de tema
    const tableAuditorsTheme = theme ? 'tableAuditorsDark' : 'tableAuditorsLight';
    const popUpTheme = theme ? 'popUpDark' : 'popUpLight';

    // Funções
    const loadAuditors = async () => {
        if(isAuth){
            const res = await getAuditors().catch(err => err.response)
    
            const { data, status } = res;
    
            if (status >= 401) {
                setHasPermissions(false)
                return
            }
            setHasPermissions(true);
            setUsers(data)
        }
    }

    useEffect(() => {
        setNewRolesUser([permission])
    }, [permission])

    useEffect(() => {
        (async () => {
            firebaseAuth.onAuthStateChanged(async user => {
                if (user) {
                    const res = await getAuditors().catch(err => err.response)
                    const { data, status } = res;
            
                    if (status >= 401) {
                        setHasPermissions(false)
                        return
                    }
                    setHasPermissions(true);
                    setUsers(data)
                }
              })
        })()
    }, [idUser, newRolesUser])

    return (
        <>
            <table className={`tableAuditors ${tableAuditorsTheme}`}>
                <thead>
                    <tr>
                        <th className='activeAuditors'>
                            USUÁRIOS EXISTENTES
                        </th>
                        <th className='iconAuditors'>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hasPermissions ?
                        users.map((user, key) => {
                            return (
                                <tr key={key}>
                                    <td>
                                        <span>
                                            {user.email}
                                        </span>

                                        <span>
                                            ( {user.roles} )
                                        </span>
                                    </td>


                                    <td>
                                        <div className='iconBoxAuditors'>
                                            <IconEditarHighLight className='iconTable' onClick={() => { popUpEdit === false ? setPopUpEdit(true) : setPopUpEdit(false); setIdUser(user.uid); setRoleUser(user.roles); }} />
                                            <IconLixeiraHighLight className='iconTable' onClick={() => { setPopUpDelete(true); setIdUser(user.uid); setRoleUser(user.role) }} />

                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr>
                            <td>
                                <span></span>
                            </td>
                            <td>
                                <div className='iconBoxAuditors'>
                                    <IconEditarHighLight className='iconTable' />
                                    <IconLixeiraHighLight className='iconTable' />
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            {
                popUpEdit
                &&
                <div className={`popUpEdit ${popUpTheme}`}>
                    <SelectPermission />
                    {theme ?
                        <IconSalvarDark className='iconSave'
                            onClick={() => {
                                editUser(idUser, newRolesUser);
                                setPopUpEdit(false);
                                setIdUser('');
                                toast.success('Permissão alterada com sucesso!', {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "dark",
                                });
                                loadAuditors()
                            }} />
                        :
                        <IconSalvarLight className='iconSave'
                            onClick={() => {
                                editUser(idUser, newRolesUser);
                                setPopUpEdit(false);
                                setIdUser('');
                                toast.success('Permissão alterada com sucesso!', {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                loadAuditors()
                            }} />
                    }
                </div>
            }
            {
                popUpDelete
                &&
                <div className={`popUpDelete ${popUpTheme}`}>
                    <span>Você tem certeza?</span>
                    <div>
                        <div
                            onClick={() => {
                                deleteUser(idUser);
                                setPopUpDelete(false); 
                                setIdUser(''); 
                                setAlertTheme(theme ? "dark":"light")
                                toast.success('Excluído com sucesso!', {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: `${alertTheme}`,
                                });
                                loadAuditors()
                            }} >
                            Sim
                        </div>
                        <div onClick={() => { setPopUpDelete(false); setIdUser(''); loadAuditors() }} >
                            Não
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default AuditorsTable