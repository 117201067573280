import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { GeneralContext } from './context/GeneralContext';

import Login from './pages/Login';
import Welcome from './pages/Welcome';
import Dashboard from './pages/Dashboard';
import Activity from './pages/Activity';
import Chats from './pages/Chats';
import Auditors from './pages/Auditors';
import Alerts from './pages/Alerts';
import EditProfile from './pages/EditProfile';

import { firebaseAuth } from "./Services/firebase"

import { Navigate } from 'react-router-dom';
import { getInfos } from './api/api';

import { firebaseStorage } from './Services/firebase';

const RoutesApp = () => {

  // Variáveis de estado
  const [theme, setTheme] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [company, setCompany] = useState('Selecione uma empresa');
  const [isAuth, setIsAuth] = useState(false);
  const [loader, setLoader] = useState(false);
  const [agent, setAgent] = useState('Selecione');

  const [pageCurrent, setPageCurrent] = useState(0);
  const [isFilter, setIsFilter] = useState(false);

  const [totRows, setTotRows] = useState(0);

  const [agentWorkchat, setAgentWorkchat] = useState('');
  const [agentComments, setAgentComments] = useState('');
  const [agentPosts, setAgentPosts] = useState('');
  const [agentFeed, setAgentFeed] = useState('');

  const [agentWorkchatName, setAgentWorkchatName] = useState('');
  const [agentCommentsName, setAgentCommentsName] = useState('');
  const [agentPostsName, setAgentPostsName] = useState('');
  const [agentFeedName, setAgentFeedName] = useState('');

  const [type, setType] = useState(null);
  const [typeName, setTypeName] = useState('');
  const [content, setContent] = useState(null);
  const [permission, setPermission] = useState([]);
  const [permissionName, setPermissionName] = useState('');

  const [userName, setUserName] = useState('')
  const [urlImage, setUrlImage] = useState('')

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        (async () => {
          setIsAuth(true)
          const res = await getInfos(`general/profile`)
          setUserName(res.data[0])
          const urlImage = await firebaseStorage.ref('images').child('profile').getDownloadURL();
          setUrlImage(urlImage)
          
        })()
      } else {
        setIsAuth(false)
        return <Navigate to="/" replace />
      }
    })
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route isAuth={false} exact path="/"
          element={
            <GeneralContext.Provider value={{ isAuth, setIsAuth, setUrlImage, setUserName }}>
              <Login />
            </GeneralContext.Provider>
          }
        />
        <Route path="/pagina-inicial"
          element={
            <GeneralContext.Provider
              value={{
                theme, setTheme,
                toggleMenu, setToggleMenu,
                isAuth, setIsAuth,
                urlImage, setUrlImage,
                userName, setUserName
              }}
            >
              {/* <PrivateRoute> */}
              <Welcome />
              {/* </PrivateRoute> */}
            </GeneralContext.Provider>

          }
        />
        <Route
          path="/dashboard"
          element={
            <GeneralContext.Provider value={{
              theme, setTheme,
              toggleMenu, setToggleMenu,
              initialDate, setInitialDate,
              finalDate, setFinalDate,
              company, setCompany,
              isAuth, setIsAuth,
              urlImage, setUrlImage,
              userName, setUserName,
              loader, setLoader,
            }}>
              {/* <PrivateRoute> */}
              <Dashboard />
              {/* </PrivateRoute> */}
            </GeneralContext.Provider>
          }
        />
        <Route path="/atividades"
          element={
            <GeneralContext.Provider value={{
              theme, setTheme,
              toggleMenu, setToggleMenu,
              initialDate, setInitialDate,
              finalDate, setFinalDate,
              company, setCompany,
              isAuth, setIsAuth,
              loader, setLoader,
              totRows, setTotRows,
              pageCurrent, setPageCurrent,
              isFilter, setIsFilter,
              agentWorkchat, setAgentWorkchat,
              agentComments, setAgentComments,
              agentPosts, setAgentPosts,
              agentFeed, setAgentFeed,
              agentWorkchatName, setAgentWorkchatName,
              agentCommentsName, setAgentCommentsName,
              agentPostsName, setAgentPostsName,
              agentFeedName, setAgentFeedName,
              content, setContent,
              type, setType,
              typeName, setTypeName,
              agent, setAgent,
              urlImage, setUrlImage,
              userName, setUserName,
            }}>
              {/* <PrivateRoute> */}
              <Activity />
              {/* </PrivateRoute> */}
            </GeneralContext.Provider>

          }
        />
        <Route
          path="/conversas"
          element={
            <GeneralContext.Provider value={{
              theme, setTheme,
              toggleMenu, setToggleMenu,
              company, setCompany,
              isAuth, setIsAuth,
              urlImage, setUrlImage,
              userName, setUserName,
              loader, setLoader,
            }}>
              {/* <PrivateRoute> */}
              <Chats />
              {/* </PrivateRoute> */}
            </GeneralContext.Provider>
          }
        />
        <Route path="/auditores"
          element={
            <GeneralContext.Provider value={{
              theme, setTheme,
              toggleMenu, setToggleMenu,
              company, setCompany,
              isAuth, setIsAuth,
              permission, setPermission,
              urlImage, setUrlImage,
              userName, setUserName,
              permissionName, setPermissionName,
            }}>
              {/* <PrivateRoute> */}
              <Auditors />
              {/* </PrivateRoute> */}
            </GeneralContext.Provider>

          }
        />
        <Route path="/alertas"
          element={
            <GeneralContext.Provider value={{
              theme, setTheme,
              toggleMenu, setToggleMenu,
              company, setCompany,
              isAuth, setIsAuth,
              permission, setPermission,
              urlImage, setUrlImage,
              userName, setUserName,
              permissionName, setPermissionName,
            }}>
              {/* <PrivateRoute> */}
              <Alerts />
              {/* </PrivateRoute> */}
            </GeneralContext.Provider>
          }
        />
        <Route path="/editar-perfil"
          element={
            <GeneralContext.Provider value={{
              theme, setTheme,
              toggleMenu, setToggleMenu,
              isAuth, setIsAuth,
              urlImage, setUrlImage,
              userName, setUserName,
            }}>
              {/* <PrivateRoute> */}
              <EditProfile />
              {/* </PrivateRoute> */}
            </GeneralContext.Provider>
          }
        />
        <Route path="*" component={() => <div>Not found</div>} />
      </Routes>
    </BrowserRouter >
  );
}

export default RoutesApp;