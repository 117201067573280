import React, { useContext } from 'react';

import '../../assets/css/components/DateFilter.css';
import { GeneralContext } from '../../context/GeneralContext';

const DateFilter = () => {

  const { theme, setInitialDate, initialDate } = useContext(GeneralContext);

  //Variáveis de CSS
  const labelTheme = theme ? 'labelDark' : 'labelLight';
  const inputTheme = theme ? 'dateInputDark' : 'dateInputLight';
  
  //Funções
  const getInitialDate = () => { 
      const initialValue = document.getElementById('initial').value;
      setInitialDate(initialValue)
  }

  return (
    <div className='dateFilter'>
      <span className={`${labelTheme}`}>Inicial</span>
      <input type="date" className={`dateInput ${inputTheme}`} id="initial"
      onChange={getInitialDate} value={initialDate}
      />
    </div>
  );
};

export default DateFilter;