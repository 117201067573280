import React, { useState, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';

import '../../assets/css/components/MainMenu.css'
import { GeneralContext } from '../../context/GeneralContext';

import UserMenu from '../molecules/UserMenu'

// ícones tema escuro
import {ReactComponent as IconEdiscoveryDark} from '../../assets/img/icons/darkmode/IconEdiscovery.svg';
import {ReactComponent as IconDashboardDark} from '../../assets/img/icons/darkmode/IconDashboard.svg';
import {ReactComponent as IconAtividadesDark} from '../../assets/img/icons/darkmode/IconAtividades.svg';
import {ReactComponent as IconConversasDark} from '../../assets/img/icons/darkmode/IconConversas.svg';
import {ReactComponent as IconAuditoresDark} from '../../assets/img/icons/darkmode/IconAuditores.svg';
import {ReactComponent as IconArrowDark} from '../../assets/img/icons/darkmode/IconArrow.svg';
import {ReactComponent as IconAlertsDark} from '../../assets/img/icons/darkmode/IconAlerts.svg';

// Ícones tema claro
import {ReactComponent as IconEdiscoveryLight} from '../../assets/img/icons/lightmode/IconEdiscovery.svg';
import {ReactComponent as IconDashboardLight} from '../../assets/img/icons/lightmode/IconDashboard.svg';
import {ReactComponent as IconAtividadesLight} from '../../assets/img/icons/lightmode/IconAtividades.svg';
import {ReactComponent as IconConversasLight} from '../../assets/img/icons/lightmode/IconConversas.svg';
import {ReactComponent as IconAuditoresLight} from '../../assets/img/icons/lightmode/IconAuditores.svg';
import {ReactComponent as IconArrowLight} from '../../assets/img/icons/lightmode/IconArrow.svg';
import {ReactComponent as IconAlertsLight} from '../../assets/img/icons/lightmode/IconAlerts.svg';

// Ícones highlight
import {ReactComponent as IconEdiscoveryHighlight} from '../../assets/img/icons/highlight/IconEdiscovery.svg';
import {ReactComponent as IconDashboardHighlight} from '../../assets/img/icons/highlight/IconDashboard.svg';
import {ReactComponent as IconAtividadesHighlight} from '../../assets/img/icons/highlight/IconAtividades.svg';
import {ReactComponent as IconConversasHighlight} from '../../assets/img/icons/highlight/IconConversas.svg';
import {ReactComponent as IconAuditoresHighlight} from '../../assets/img/icons/highlight/IconAuditores.svg';
import {ReactComponent as IconAlertsHighlight} from '../../assets/img/icons/highlight/IconAlerts.svg';


const MainMenu = () => {

    const { theme, toggleMenu, setToggleMenu } = useContext(GeneralContext);

    //Função para pegar a rota
    let location = useLocation();

    //Variáveis
    let path = location.pathname;

    //Estados
    const [toggleUserMenu, setToggleUserMenu] = useState(false);

    //Funções
    const toggleMainMenu = () => {
        setToggleMenu(!toggleMenu)
    }

    //Variáveis de seleção de página
    const mainPageHighlight = path === '/pagina-inicial' ? 'optionHighlight' : '';
    const generalHighlight = path === '/dashboard' ? 'optionHighlight' : '';
    const activityHighlight = path === '/atividades' ? 'optionHighlight' : '';
    const chatsHighlight = path === '/conversas' ? 'optionHighlight' : '';
    const auditorsHighlight = path === '/auditores' ? 'optionHighlight' : '';
    const alertsHighlight = path === '/alertas' ? 'optionHighlight' : '';

    //Eventos
    const openMenu = toggleMenu ? 'mainMenuOpen' : 'mainMenuClose';
    const toggleIcon = toggleMenu ? 'toggleMenuOpen' : 'toggleMenuClose';
    const arrowMenu = toggleMenu ? 'arrowMenuOpen' : 'arrowMenuClose';
    const optionMenu = toggleMenu ? 'optionOpen' : 'optionClose';
    const iconMenu = toggleMenu ? 'iconMenuOpen' : 'iconMenuClose';
    const userInfo = toggleMenu ? 'userInfoOpen' : 'userInfoClose';

    //Tema
    const mainMenuTheme = theme ? 'mainMenuDark' : 'mainMenuLight';
    const toggleMenuTheme = theme ? 'toggleMenuDark' : 'toggleMenuLight';
    const optionTheme = theme ? 'optionDark' : 'optionLight';
    const userInfoTheme = theme ? 'userInfoDark' : 'userInfoLight';

    //Variáveis de tema de ícones
    const iconEdiscovery = theme ? <IconEdiscoveryDark className={`iconMenu ${iconMenu}`} /> : <IconEdiscoveryLight className={`iconMenu ${iconMenu}`} />;
    const iconDashboard = theme ? <IconDashboardDark className={`iconMenu ${iconMenu}`} /> : <IconDashboardLight className={`iconMenu ${iconMenu}`} />;
    const iconAtividades = theme ? <IconAtividadesDark className={`iconMenu ${iconMenu}`} /> : <IconAtividadesLight className={`iconMenu ${iconMenu}`} />;
    const iconConversas = theme ? <IconConversasDark className={`iconMenu ${iconMenu}`} /> : <IconConversasLight className={`iconMenu ${iconMenu}`} />;
    const iconAuditores = theme ? <IconAuditoresDark className={`iconMenu ${iconMenu}`} /> : <IconAuditoresLight className={`iconMenu ${iconMenu}`} />;
    const iconAlertas = theme ? <IconAlertsDark className={`iconMenu ${iconMenu}`} /> : <IconAlertsLight className={`iconMenu ${iconMenu}`} />;

    return (
        <div className={`mainMenu ${openMenu} ${mainMenuTheme}`}>
            <div className={`toggleMenu ${toggleIcon} ${toggleMenuTheme}`} onClick={toggleMainMenu}>
                {theme ? <IconArrowDark className={`${arrowMenu}`} /> : <IconArrowLight className={`${arrowMenu}`} />}
            </div>
            <div className='optionMenu'>
                <Link to='/pagina-inicial' className='link'>
                    <div className={`option ${optionMenu} ${optionTheme} ${mainPageHighlight}`}>
                        {path === '/pagina-inicial' ? <IconEdiscoveryHighlight className={`iconMenu ${iconMenu}`} /> : iconEdiscovery }
                        {toggleMenu && <span>Página inicial</span>}
                    </div>
                </Link>
                <Link to='/dashboard' className='link'>
                    <div className={`option ${optionMenu} ${optionTheme} ${generalHighlight}`}>
                        {path === '/dashboard' ? <IconDashboardHighlight className={`iconMenu ${iconMenu}`} /> : iconDashboard }
                        {toggleMenu && <span>Dashboard</span>}
                    </div>
                </Link>
                <Link to='/atividades' className='link'>
                    <div className={`option ${optionMenu} ${optionTheme} ${activityHighlight}`}>
                        {path === '/atividades' ? <IconAtividadesHighlight className={`iconMenu ${iconMenu}`} /> : iconAtividades }
                        {toggleMenu && <span>Atividades</span>}
                    </div>
                </Link>
                <Link to='/conversas' className='link'>
                    <div className={`option ${optionMenu} ${optionTheme} ${chatsHighlight}`}>
                        {path === '/conversas' ? <IconConversasHighlight className={`iconMenu ${iconMenu}`} /> : iconConversas }
                        {toggleMenu && <span>Conversas</span>}
                    </div>
                </Link>
                <Link to='/auditores' className='link'>
                    <div className={`option ${optionMenu} ${optionTheme} ${auditorsHighlight}`}>
                        {path === '/auditores' ? <IconAuditoresHighlight className={`iconMenu ${iconMenu}`} /> : iconAuditores }
                        {toggleMenu && <span>Auditores</span>}
                    </div>
                </Link>
                <Link to='/alertas' className='link'>
                    <div className={`option ${optionMenu} ${optionTheme} ${alertsHighlight}`}>
                        {path === '/alertas' ? <IconAlertsHighlight className={`iconMenu ${iconMenu}`} /> : iconAlertas }
                        {toggleMenu && <span>Alertas</span>}
                    </div>
                </Link>
            </div>
            {
                localStorage.getItem('auth') && localStorage.getItem('url') && localStorage.getItem('user') &&
                <div className={`userInfo ${userInfo} ${userInfoTheme}`} onClick={()=>setToggleUserMenu(!toggleUserMenu)}>
                    <img src={`${localStorage.getItem('url')}`} alt='Foto do usuário'></img>
                    {toggleMenu && <span>{localStorage.getItem('user')}</span>}
                </div>
            }
            {toggleUserMenu && <UserMenu />}
        </div>
    );
}
 
export default MainMenu