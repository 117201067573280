import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

import firebase from 'firebase/compat/app'

import 'firebase/compat/auth'
import 'firebase/compat/storage'
import config from '../config/firebase.json'
import gcloud from '../config/gcloud.json'

const credentials = {
    apiKey: config.apiKey,
    authDomain: `${gcloud.projectId}.firebaseapp.com`,
    databaseURL: `https://${gcloud.projectId}.firebaseio.com`,
    projectId: gcloud.projectId,
    storageBucket: `${gcloud.projectId}.appspot.com`,
    messagingSenderId: config.messagingSenderId,
    appId: config.appId,
}

const app = firebase.initializeApp(credentials)

export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(config.recaptchaKey || ""),
    isTokenAutoRefreshEnabled: true
});

export const firebaseAuth = app.auth()
export const firebaseStorage = firebase.storage()

export async function setImgStorage(file) {
    firebaseStorage.ref('images').child('profile').put(file)
    return firebaseStorage.ref('images').child('profile').getDownloadURL()
}

export async function login(email, password) {
    return firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
        return firebaseAuth.signInWithEmailAndPassword(email, password)
    })
}

export function getUser() {
    return firebaseAuth.currentUser
}