import React, { useContext } from 'react'

import '../../../assets/css/components/Tables/DetailsModal/WorkchatDetail.css';

import { GeneralContext } from '../../../context/GeneralContext';

import { ReactComponent as IconFecharDark } from '../../../assets/img/icons/darkmode/IconFechar.svg';

import { ReactComponent as IconFecharLight } from '../../../assets/img/icons/lightmode/IconFechar.svg';

const PostDetail = (props) => {

    const { theme, toggleMenu } = useContext(GeneralContext);

    // Variáveis de condições
    const widthModal = toggleMenu ? 'workchatMenuOpen' : 'workchatMenuClose';
    const workchatBoxModalTheme = theme ? 'workchatBoxModalDark' : 'workchatBoxModalLight';
    const infoBoxTheme = theme ? 'infoBoxDark' : 'infoBoxLight';

    let content, content_id, created_time, deleted_time, group_id, group_name, group_privacy, permalink, user_id, user_name, verb;

    props.row.forEach((RowSelected) => {
        if (RowSelected.content_id === props.id) {
            content = RowSelected.content;
            content_id = RowSelected.content_id;
            created_time = RowSelected.created_time;
            deleted_time = RowSelected.deleted_time;
            group_id = RowSelected.group_id;
            group_name = RowSelected.from_name;
            group_privacy = RowSelected.group_privacy;
            permalink = RowSelected.permalink;
            user_id = RowSelected.user_id;
            user_name = RowSelected.user_name;
            verb = RowSelected.verb;
        }
    })

    return (
        <div className={`workchatModal ${widthModal}`}>
            <div className={`workchatBoxModal ${workchatBoxModalTheme}`}>
                <div className='workchatTitleBox'>
                    <span className='titleDetails'>Detalhes</span>
                    {   theme ?
                        <IconFecharDark className='closeIcon' onClick={() => props.setViewDetail(false)} />
                        :
                        <IconFecharLight className='closeIcon' onClick={() => props.setViewDetail(false)} />
                    }
                </div>
                <div className='contentModal'>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Criação</span>
                        <span>{created_time}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Id</span>
                        <span>{content_id}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Id do grupo</span>
                        <span>{group_id}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Id do usuário</span>
                        <span>{user_id}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Grupo</span>
                        <span>{group_name}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Privacidade</span>
                        <span>{group_privacy}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Usuário</span>
                        <span>{user_name}</span>
                    </div>
                    <div className={`infoBox infoBoxScroll ${infoBoxTheme}`}>
                        <span>Conteúdo</span>
                        <span>{content}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Ação</span>
                        <span>{verb}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Link</span>
                        <span>{permalink}</span>
                    </div>
                    <div className={`infoBox ${infoBoxTheme}`}>
                        <span>Excluído em</span>
                        <span>{deleted_time}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostDetail