import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {firebaseAuth} from '../Services/firebase'

import gcloud from '../config/gcloud.json'

import '../assets/css/pages/Chats.css';
import { GeneralContext } from '../context/GeneralContext';

import MainMenu from '../components/organisms/MainMenu'
import Title from '../components/atoms/Title';
import SelectCompany from '../components/atoms/SelectCompany';

import { getInfos, getChat, getCsvChatBetweenUsers, getChatByEmail, getIdsByEmail, getUrlImage } from '../api/api';
import { Loading } from '../components/atoms/Loading';

import { saveAs } from "file-saver";

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Chats = (props) => {

  const { theme, toggleMenu, company, setIsAuth } = useContext(GeneralContext);

  const navigate = useNavigate();

  //Variáveis de estado
  const [selectedDashboard, setSelectedDashboard] = useState("");
  const [dashboards, setDashboards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uid1, setUid1] = useState(''); 
  const [uid2, setUid2] = useState(''); 
  const [rows, setRows] = useState([]); 
  const [hasRows, setHasRows] = useState(false);
  const [urlUser1, setUrlUser1] = useState("");
  const [urlUser2, setUrlUser2] = useState("");
  const [userName1, setUserName1] = useState("");
  const [userName2, setUserName2] = useState("");
  const [alertTheme, setAlertTheme] = useState(theme ? "dark" : "light")

  //Variáveis
  let nameDashboard = 'Selecione uma empresa';

  //Variáveis de CSS
  const contentPageToggle = toggleMenu ? 'contentPageOpen' : 'contentPageClose';
  const contentPageTheme = theme ? 'contentPageDark' : 'contentPageLight';
  const chatNameTheme = theme ? 'chatNameDark' : 'chatNameLight';
  const chatBoxTheme = theme ? 'chatBoxDark' : 'chatBoxLight';
  const senderTextTheme = theme ? 'senderTextDark' : 'senderTextLight';
  const receiverTextTheme = theme ? 'receiverTextDark' : 'receiverTextLight';
  const backgroundTheme = theme ? 'backgroundDark' : 'backgroundLight';
  const nameTheme = theme ? 'displayNamesDark' : 'displayNamesLight';

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        (async () => {
            setIsAuth(true)
            setIsLoading(true)
            const res = await getInfos(`general/communities`);
            setDashboards(res.data);
            setSelectedDashboard(company);
            setIsLoading(false);
          })();
      } else {
        setIsAuth(false)
        navigate('/')
      }
    })
  }, [props.history, company, navigate, setIsAuth])

  useEffect(() => {
    const element = document.getElementById('chatBox');
    element.scrollTop = element.scrollHeight;
  })

  const setValues = () => {
    dashboards.forEach((dash) => {
      if (dash.community_id === selectedDashboard) {
        nameDashboard = dash.community_name;
      }
    })
  }
  setValues();

  const getChatBetweenUsers = async () => {
    try{
      if (uid1 !== '' && uid2 !== '' && company !== 'Selecione uma empresa') {
        setIsLoading(true)
        const tableName = `${gcloud.projectId}.${company}_workplace`
        if (uid1.split("@").length === 2) {
          const picture = await getUrlImage(uid1, uid2, company)
          setUrlUser1(picture.data.url);
          setUrlUser2(picture.data.url2);
          setUserName1(picture.data.name);
          setUserName2(picture.data.name2);
          const res = await getChatByEmail(uid1, uid2, tableName);
          setRows(res.data)
        }
        else {
          const picture = await getUrlImage(uid1, uid2, company)
          setUrlUser1(picture.data.url);
          setUrlUser2(picture.data.url2);
          setUserName1(picture.data.name);
          setUserName2(picture.data.name2);
          const res = await getChat(uid1, uid2, tableName);
          setRows(res.data)
        }
        setHasRows(true);
      }
      else {
        setIsLoading(false);
        toast.error('Todos os campos devem ser preenchidos!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: `${alertTheme}`,
        });
      }
      setIsLoading(false)
    }catch(error){
      setIsLoading(false);
      toast.error('Conversa não encontrada!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: `${alertTheme}`,
      });
    }
  }

  const downloadCsv = async () => {
    let res
    if (uid1 !== '' && uid2 !== '' && company !== 'Selecione uma empresa') {

      const tableName = `${gcloud.projectId}.${company}_workplace`
      if (uid1.split("@").length === 2) {
        setIsLoading(true);
        const ids = await getIdsByEmail(uid1, uid2, company);
        res = await getCsvChatBetweenUsers(ids.data[0], ids.data[1], tableName);
        setIsLoading(false);
      } else {
        setIsLoading(true);
        res = await getCsvChatBetweenUsers(uid1, uid2, tableName);
        setIsLoading(false);
      }

      const blob = new Blob(["", res.data], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `csv_chatbetweenusers_${uid1}_and_${uid2}_${new Date().getTime() / 1000}.csv`);

    } else {
      setAlertTheme(theme ? "dark" : "light")
      toast.error('Todos os campos devem ser preenchidos!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: `${alertTheme}`,
      });
    }
  }

  return (
    <div className='chat'>
      <ToastContainer />
      <div className={`${backgroundTheme}`} />
      <MainMenu />
      <div className={`contentPage ${contentPageToggle} ${contentPageTheme}`}>
        <Title />
        {isLoading && <Loading />}
        <div className='companyBox'>
          <SelectCompany
            dashboards={dashboards}
            nameDashboard={nameDashboard}
          />
          <button className='btnCsv' onClick={() => { downloadCsv() }}>Download CSV</button>
        </div>
        <div className='chatInputBox'>
          <input
            type='text'
            className={`chatName ${chatNameTheme}`}
            placeholder='ID ou E-mail'
            value={uid1}
            onChange={e => { setUid1(e.target.value); setHasRows(false) }}
          />
          <input
            type='text'
            className={`chatName ${chatNameTheme}`}
            placeholder='ID ou E-mail'
            value={uid2}
            onChange={e => { setUid2(e.target.value); setHasRows(false) }}
          />
          <button className='btnInputBox' onClick={getChatBetweenUsers}>Pesquisar</button>
        </div>
        <div className={`displayNames ${nameTheme}`}>
          {userName1 === "" ? <span>Insira os usuários</span> : <span>{userName1}</span>}
          {userName2 === "" ? <span></span> : <span>{userName2}</span>}
        </div>
        <div className={`chatBox ${chatBoxTheme}`} id='chatBox'>
          <div className='chatMessage'>
            {
              hasRows ?
                rows.map((row) => {
                  return (
                    row.from_id === uid1 || row.email === uid2
                      ?
                      <div className='senderSide'>
                        <img src={urlUser1} alt="User 1"></img>
                        <div className={`senderText ${senderTextTheme}`} >{row.message}</div>
                      </div>
                      :
                      <div className='receiverSide'>
                        <div className={`receiverText ${receiverTextTheme}`}>{row.message}</div>
                        <img src={urlUser2} alt="User 1"></img>
                      </div>
                  )
                })
                :
                <>
                  <div className={`senderText ${senderTextTheme}`}>Selecione uma empresa</div>
                  <div className={`senderText ${senderTextTheme}`}>Insira o Id ou e-mail do usuário 1 no primeiro campo acima</div>
                  <div className={`receiverText ${receiverTextTheme}`}>Insira o Id ou e-mail do usuário 2 no segundo campo acima</div>
                  <div className={`receiverText ${receiverTextTheme}`}>Clique em pesquisar e aguarde</div>
                </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chats;
