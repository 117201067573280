import React, { useContext } from 'react';

import '../../assets/css/components/DashboardChart.css';
import { GeneralContext } from '../../context/GeneralContext';

import {Chart as ChartJS, BarElement, CategoryScale, LinearScale} from 'chart.js'
import {Bar} from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement
)

const DashboardChart = (props) => {

    const { theme } = useContext(GeneralContext);

    const chartTheme = theme ? '#FAFBFC' : '#2A2D30';

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                ticks: {
                    color: chartTheme
                },
                beginAtZero: true
            },
            x: {
                ticks: {
                    color: chartTheme
                }
            } 
        }
    }

    const dataChart = {
        labels: [props.data[2].name,props.data[1].name,props.data[4].name,props.data[3].name,props.data[0].name],
        datasets: [{
          label: 'Dashboard chart',
          data: [props.data[2].total,props.data[1].total,props.data[4].total,props.data[3].total,props.data[0].total],
          backgroundColor: [
            '#044BD9'
          ],
          borderWidth: 1
        }]
    }

    return (
        <div className='chartBox'>
            <Bar data={dataChart} options={options}/>
        </div>
    )
}

export default DashboardChart