import React, { useContext } from 'react'

import '../../../assets/css/components/Tables/DetailsModal/WorkchatDetail.css'
import { GeneralContext } from '../../../context/GeneralContext';

import { ReactComponent as IconFecharDark } from '../../../assets/img/icons/darkmode/IconFechar.svg';

import { ReactComponent as IconFecharLight } from '../../../assets/img/icons/lightmode/IconFechar.svg';

const WorkchatDetail = (props) => {


    const { theme, toggleMenu } = useContext(GeneralContext);
    
    // Variáveis de condições
    const widthModal = toggleMenu ? 'workchatMenuOpen' : 'workchatMenuClose';
    const workchatBoxModalTheme = theme ? 'workchatBoxModalDark' : 'workchatBoxModalLight';
    const infoBoxTheme = theme ? 'infoBoxDark' : 'infoBoxLight';

    let created_time, from_id, from_name, id, message, to_community_id, to_email, to_id, to_name;

    props.row.forEach((RowSelected) => {
        if(RowSelected.id === props.id){
            created_time = RowSelected.created_time;
            from_id = RowSelected.from_id;
            from_name = RowSelected.from_name;
            id = RowSelected.id;
            message = RowSelected.message;
            to_community_id = RowSelected.to_community_id;
            to_email = RowSelected.to_email;
            to_id = RowSelected.to_id;
            to_name = RowSelected.to_name;
        }
    })


    return (
            <div className={`workchatModal ${widthModal}`}>
                <div className={`workchatBoxModal ${workchatBoxModalTheme}`}>
                    <div className='workchatTitleBox'>
                        <span className='titleDetails'>Detalhes</span>
                        {   theme ?
                            <IconFecharDark className='closeIcon' onClick={() => props.setViewDetail(false)} />
                            :
                            <IconFecharLight className='closeIcon' onClick={() => props.setViewDetail(false)} />
                        }
                    </div>
                    <div className='contentModal'>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>Id</span>
                            <span>{id}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>Criação</span>
                            <span>{created_time}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>From_id</span>
                            <span>{from_id}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>Autor</span>
                            <span>{from_name}</span>
                        </div>
                        <div className={`infoBox infoBoxScroll ${infoBoxTheme}`}>
                            <span>Mensagem</span>
                            <span>{message}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>To.Id</span>
                            <span>{to_id}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>Usuário</span>
                            <span>{to_name}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>To.Email</span>
                            <span>{to_email}</span>
                        </div>
                        <div className={`infoBox ${infoBoxTheme}`}>
                            <span>To.Community.Id</span>
                            <span>{to_community_id}</span>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default WorkchatDetail