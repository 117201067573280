import React, { useEffect, useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { firebaseAuth } from '../Services/firebase';

import '../assets/css/pages/Dashboard.css';
import { GeneralContext } from '../context/GeneralContext';

import MainMenu from '../components/organisms/MainMenu'
import Title from '../components/atoms/Title';
import SelectCompany from '../components/atoms/SelectCompany'
import DashboardChart from '../components/atoms/DashboardChart'
import { Loading } from '../components/atoms/Loading';

import { getInfos } from '../api/api'

const Dashboard = (props) => {

  const { theme, toggleMenu, company, setIsAuth } = useContext(GeneralContext)

  const navigate = useNavigate();

  //Variáveis de estado
  const [selectedDashboard, setSelectedDashboard] = useState("");
  const [dashboards, setDashboards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //Variáveis
  let status, posts, comments, timelineComments, workchat, nameDashboard = 'Selecione uma empresa';

  //Variáveis de CSS
  const contentPageToggle = toggleMenu ? 'contentPageOpen' : 'contentPageClose';
  const contentPageTheme = theme ? 'contentPageDark' : 'contentPageLight';
  const textTheme = theme ? 'textDark' : 'textLight';
  const backgroundTheme = theme ? 'backgroundDark' : 'backgroundLight';

  //Funções
  useEffect(() => {
    firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        (async () => {
          setIsAuth(true)
          setIsLoading(true)
          const res = await getInfos(`general/counters`);
          setDashboards(res.data);
          setSelectedDashboard(company)
          setIsLoading(false)
        })();
      } else {
        setIsAuth(false)
        navigate('/')
      }
    })
    
  }, [company, props.history]);

  const setValues = () => {
    dashboards.forEach((dash) => {
      if (dash.community_id === selectedDashboard) {
        status = dash.data.status;
        workchat = dash.data.workchat;
        comments = dash.data.comments;
        posts = dash.data.posts;
        timelineComments = dash.data.timelineComments;
        nameDashboard = dash.community_name;
      }
    })
  }

  setValues()
  
  //Valores para gráfico
  const data = [
    { name: "Workchat", total: workchat },
    { name: "Posts", total: posts },
    { name: "Status", total: status },
    { name: "Timeline", total: timelineComments },
    { name: "Comment", total: comments },
  ];

  return (
    <div className='dashboard'>
      <MainMenu />
      <div className={`contentPage ${contentPageToggle} ${contentPageTheme}`}>
        <div className={`${backgroundTheme}`} />
        <Title />
        {isLoading && <Loading />}
        <SelectCompany
          dashboards={dashboards}
          nameDashboard={nameDashboard}
        />
        <div className='analysisDataBox'>
          <div className='analysisData'>
            <div className={`titleDataBox ${textTheme}`}>
              <span>Tags</span>
            </div>
            <div className={`dataBox ${textTheme}`}>
              <span className='dataName'>Feed</span>
              <span className='dataValue'>{status}</span>
            </div>
            <div className={`dataBox ${textTheme}`}>
              <span className='dataName'>Posts</span>
              <span className='dataValue'>{posts}</span>
            </div>
            <div className={`dataBox ${textTheme}`}>
              <span className='dataName'>Comentários</span>
              <span className='dataValue'>{comments}</span>
            </div>
            <div className={`dataBox ${textTheme}`}>
              <span className='dataName'>Comentários (Timeline)</span>
              <span className='dataValue'>{timelineComments}</span>
            </div>
            <div className={`dataBox ${textTheme}`}>
              <span className='dataName'>Workchat</span>
              <span className='dataValue'>{workchat}</span>
            </div>
          </div>
          <div className='chartDashboard'>
            <DashboardChart data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;